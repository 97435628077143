import { useDispatch, useSelector } from "react-redux";

import { ErrorResponse, InviteDto } from "../../../api/types";
import { deleteInvite, resendInvite } from "../../../api/workspace";
import { toastRequested } from "../../../store/actions";
import { IRootState } from "../../../store/store";
import { getAccessToken, isAdmin } from "../../../utils/AuthUtils";
import { getErrorMessage } from "../../../utils/ErrorUtils";
import { Format, formatEpochSeconds } from "../../../utils/TimeUtils";
import Button from "../layout/utils/Button";
import Card from "../layout/utils/Card";
import Icon from "../layout/utils/Icon";
import Tooltip from "../layout/utils/Tooltip";

export function InvitesTable({
  invites,
  activeWorkspaceId,
  reloadInvites,
}: {
  invites: InviteDto[];
  activeWorkspaceId: string;
  reloadInvites: () => void;
}) {
  const dispatch = useDispatch();
  const activeCustomerWorkspace = useSelector(
    (state: IRootState) => state.activeWorkspace
  );

  function handleDeleteInvite(id: string): void {
    let accessToken = getAccessToken();
    if (!accessToken) return;

    deleteInvite(accessToken, activeWorkspaceId, id)
      .then(() => {
        reloadInvites();
        dispatch(
          toastRequested({ type: "success", message: "Invite deleted" })
        );
      })
      .catch((errorResponse: ErrorResponse) => {
        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      });
  }

  function handleResendInvite(id: string): void {
    let accessToken = getAccessToken();
    if (!accessToken) return;

    resendInvite(accessToken, activeWorkspaceId, id)
      .then(() => {
        dispatch(
          toastRequested({ type: "success", message: "Invitation email sent" })
        );
      })
      .catch((errorResponse: ErrorResponse) => {
        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      });
  }

  return (
    <Card title='Invites'>
      <div className='p-3'>
        {/* Table */}
        <div className='overflow-x-auto'>
          <table className='table-auto w-full'>
            {/* Table header */}
            <thead className='text-xs uppercase text-slate-400 bg-slate-50 rounded-sm'>
              <tr>
                <th className='p-2'>
                  <div className='font-semibold text-left'>
                    Email
                    <Icon className='bi-caret-down-fill ml-1' size='base' />
                  </div>
                </th>
                <th className='p-2'>
                  <div className='font-semibold text-left'>Invite sent</div>
                </th>
                <th className='p-2'></th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className='text-sm font-medium divide-y divide-slate-100'>
              {/* Row */}
              {invites.map((invite: InviteDto) => (
                <tr key={invite.id}>
                  <td className='p-2'>{invite.email}</td>
                  <td className='p-2'>
                    {formatEpochSeconds(invite.createdAt, Format.SHORT)}
                  </td>

                  <td className='p-2'>
                    <div className='text-right min-w-[5rem]'>
                      {isAdmin(activeCustomerWorkspace) ? (
                        <>
                          <Tooltip text='Resend'>
                            <Button
                              iconClass='bi-arrow-clockwise'
                              size='small'
                              iconSize='lg'
                              color='primary'
                              onClick={() => handleResendInvite(invite.id)}
                            />
                          </Tooltip>
                          <Tooltip text='Delete'>
                            <Button
                              iconClass='bi-x-lg'
                              size='small'
                              iconSize='lg'
                              color='alert'
                              onClick={() => handleDeleteInvite(invite.id)}
                              className='ml-1'
                            />
                          </Tooltip>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
}
