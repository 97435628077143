import { FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createSearchParams,
  Link,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { registerUser } from "../../../api/auth";
import { ErrorCode, ErrorResponse } from "../../../api/types";
import { toastRequested } from "../../../store/actions";
import { getAccessToken } from "../../../utils/AuthUtils";
import { getErrorMessage } from "../../../utils/ErrorUtils";
import { getOAuthLink, OAuthLinkProps } from "../../../utils/OAuth2Utils";
import { getPrivacyPolicyUrl, getTermsUrl } from "../../../utils/UrlUtils";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState<ErrorCode | null>(null);

  let [searchParams] = useSearchParams();
  let inviteId = searchParams.get("invite_id");

  useEffect(() => {
    let accessToken = getAccessToken();
    if (accessToken) {
      navigate("/");
    }
  }, [navigate]);

  function handleSubmit(e: FormEvent) {
    e.preventDefault();

    registerUser(username, password, inviteId)
      .then(() => {
        navigate({
          pathname: "/verify-email-info",
          search: `?${createSearchParams({
            email: username,
          })}`,
        });
      })
      .catch((errorResponse: ErrorResponse) => {
        setFormError(null);

        if (
          errorResponse.errorCode === ErrorCode.INVALID_REQUEST &&
          errorResponse.data?.email
        ) {
          let errorCode = ErrorCode.INVALID_EMAIL;

          setFormError(errorCode);

          dispatch(
            toastRequested({
              type: "error",
              message: getErrorMessage({
                errorCode,
                correlationId: "",
              }),
            })
          );
        } else if (
          errorResponse.errorCode === ErrorCode.INVALID_REQUEST &&
          errorResponse.data?.password
        ) {
          let errorCode = ErrorCode.PASSWORD_NOT_STRONG_ENOUGH;

          setFormError(errorCode);

          dispatch(
            toastRequested({
              type: "error",
              message: getErrorMessage({
                errorCode,
                correlationId: "",
              }),
            })
          );
        } else {
          setFormError(errorResponse.errorCode);

          dispatch(
            toastRequested({
              type: "error",
              message: getErrorMessage(errorResponse),
            })
          );
        }
      });
  }

  function handleNavigateLogin() {
    let searchParams = undefined;
    if (inviteId) {
      searchParams = `?${createSearchParams({
        redirect: "/join-workspace?invite=" + inviteId,
      })}`;
    }

    navigate({
      pathname: "/login",
      search: searchParams,
    });
  }

  function isUsernameError() {
    return (
      formError === ErrorCode.USERNAME_ALREADY_IN_USE ||
      formError === ErrorCode.INVALID_EMAIL
    );
  }

  function isPasswordError() {
    return formError === ErrorCode.PASSWORD_NOT_STRONG_ENOUGH;
  }

  function handleSignupWithGoogle(event: FormEvent) {
    event.preventDefault();

    let linkParams: OAuthLinkProps = { authProvider: "google" };
    if (inviteId) linkParams.inviteId = inviteId;

    let url = getOAuthLink(linkParams);

    window.location.href = url;
  }

  function handleSignupWithGithub(event: FormEvent) {
    event.preventDefault();

    let linkParams: OAuthLinkProps = { authProvider: "github" };
    if (inviteId) linkParams.inviteId = inviteId;

    let url = getOAuthLink(linkParams);

    window.location.href = url;
  }

  return (
    <section className='font-inter antialiased tracking-tight bg-gradient-to-b from-gray-100 to-white'>
      <div className='max-w-6xl mx-auto px-4 sm:px-6'>
        <div className='pt-16 sm:pt-22 md:pt-24 pb-28'>
          {/* Page header */}
          <div className='max-w-3xl mx-auto text-center pb-12 sm:pb-16 md:pb-18 lg:pb-26 '>
            <h1 className='font-extrabold leading-tight text-2xl sm:text-3xl md:text-5xl'>
              Welcome. We exist to make Pdf generation easier.
            </h1>
          </div>

          {/* Form */}
          <div className='max-w-sm mx-auto'>
            <form onSubmit={handleSubmit} autoComplete='off'>
              <div className='flex flex-wrap -mx-3 mb-4'>
                <div className='w-full px-3'>
                  <label
                    className='block text-gray-800 text-sm font-medium mb-1'
                    htmlFor='email'
                  >
                    Email <span className='text-red-600'>*</span>
                  </label>
                  <input
                    id='email'
                    type='email'
                    className={`form-input w-full text-gray-800 ${
                      isUsernameError() ? "!border-rose-500" : ""
                    }`}
                    placeholder='Enter your email address'
                    required
                    onChange={(e) => setUsername(e.target.value)}
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='flex flex-wrap -mx-3 mb-4'>
                <div className='w-full px-3'>
                  <label
                    className='block text-gray-800 text-sm font-medium mb-1'
                    htmlFor='password'
                  >
                    Password <span className='text-red-600'>*</span>
                  </label>
                  <input
                    id='password'
                    type='password'
                    className={`form-input w-full text-gray-800 ${
                      isPasswordError() ? "!border-rose-500" : ""
                    }`}
                    placeholder='Enter your password'
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete='new-password'
                  />
                </div>
              </div>
              <div className='flex flex-wrap -mx-3 mt-6'>
                <div className='w-full px-3'>
                  <button className='btn text-white bg-blue-600 hover:bg-blue-700 w-full'>
                    Sign up
                  </button>
                </div>
              </div>
              <div className='text-sm text-gray-500 text-center mt-3'>
                By creating an account, you agree to the{" "}
                <Link className='underline' to={getTermsUrl()}>
                  terms of service
                </Link>
                <span>, and our </span>
                <Link className='underline' to={getPrivacyPolicyUrl()}>
                  privacy policy
                </Link>
                .
              </div>
            </form>
            <div className='flex items-center my-6'>
              <div
                className='border-t border-gray-300 grow mr-3'
                aria-hidden='true'
              ></div>
              <div className='text-gray-600 italic'>Or</div>
              <div
                className='border-t border-gray-300 grow ml-3'
                aria-hidden='true'
              ></div>
            </div>
            <form>
              <div className='flex flex-wrap -mx-3 mb-3'>
                <div className='w-full px-3'>
                  <button
                    className='btn px-0 text-white bg-gray-900 hover:bg-gray-800 w-full relative flex items-center'
                    onClick={handleSignupWithGithub}
                  >
                    <svg
                      className='w-4 h-4 fill-current text-white opacity-75 shrink-0 mx-4'
                      viewBox='0 0 16 16'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M7.95 0C3.578 0 0 3.578 0 7.95c0 3.479 2.286 6.46 5.466 7.553.397.1.497-.199.497-.397v-1.392c-2.187.497-2.683-.993-2.683-.993-.398-.895-.895-1.193-.895-1.193-.696-.497.1-.497.1-.497.795.1 1.192.795 1.192.795.696 1.292 1.888.895 2.286.696.1-.497.298-.895.497-1.093-1.79-.2-3.578-.895-3.578-3.975 0-.895.298-1.59.795-2.087-.1-.2-.397-.994.1-2.087 0 0 .695-.2 2.186.795a6.408 6.408 0 011.987-.299c.696 0 1.392.1 1.988.299 1.49-.994 2.186-.795 2.186-.795.398 1.093.199 1.888.1 2.087.496.596.795 1.291.795 2.087 0 3.08-1.889 3.677-3.677 3.875.298.398.596.895.596 1.59v2.187c0 .198.1.497.596.397C13.714 14.41 16 11.43 16 7.95 15.9 3.578 12.323 0 7.95 0z' />
                    </svg>
                    <span className='flex-auto pl-16 pr-8 -ml-16'>
                      Continue with GitHub
                    </span>
                  </button>
                </div>
              </div>
              <div className='flex flex-wrap -mx-3'>
                <div className='w-full px-3'>
                  <button
                    className='btn px-0 text-white bg-red-600 hover:bg-red-700 w-full relative flex items-center'
                    onClick={handleSignupWithGoogle}
                  >
                    <svg
                      className='w-4 h-4 fill-current text-white opacity-75 shrink-0 mx-4'
                      viewBox='0 0 16 16'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M7.9 7v2.4H12c-.2 1-1.2 3-4 3-2.4 0-4.3-2-4.3-4.4 0-2.4 2-4.4 4.3-4.4 1.4 0 2.3.6 2.8 1.1l1.9-1.8C11.5 1.7 9.9 1 8 1 4.1 1 1 4.1 1 8s3.1 7 7 7c4 0 6.7-2.8 6.7-6.8 0-.5 0-.8-.1-1.2H7.9z' />
                    </svg>
                    <span className='flex-auto pl-16 pr-8 -ml-16'>
                      Continue with Google
                    </span>
                  </button>
                </div>
              </div>
            </form>
            <div className='text-gray-600 text-center mt-6'>
              <a
                className='text-blue-600 hover:underline transition duration-150 ease-in-out cursor-pointer'
                onClick={handleNavigateLogin}
              >
                Already have an account? Sign in
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
