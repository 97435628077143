import { makeRequestAndReturnBlob, makeRequestAndReturnJSON } from "./common";
import {
  ActivityLogResponse,
  AnalyticsResponse,
  ApiKeyDto,
  ApiKeysResponse,
  BillingPeriod,
  CustomerWorkspaceDto,
  CustomerWorkspaceRole,
  CustomerWorkspacesResponse,
  MessageResponse,
  ProductCode,
  SubscriptionDto,
  TemplateContentDto,
  TemplateDetailsDto,
  TemplateDetailsResponse,
  WorkspaceCustomersResponse,
  WorkspaceDto,
  WorkspaceInviteDto,
  WorkspaceInvitesResponse,
} from "./types";

export const getWorkspaces: (
  accessToken: string
) => Promise<CustomerWorkspacesResponse> = async (accessToken) => {
  return makeRequestAndReturnJSON({
    method: "GET",
    url: "/api/app/workspaces",
    accessToken,
  });
};

export const createWorkspace: (
  accessToken: string,
  productCode: ProductCode,
  billingPeriod: BillingPeriod,
  name: string
) => Promise<CustomerWorkspaceDto> = async (
  accessToken,
  productCode,
  billingPeriod,
  name
) => {
  return makeRequestAndReturnJSON({
    method: "POST",
    url: `/api/app/workspaces`,
    body: JSON.stringify({ productCode, billingPeriod, name }),
    accessToken,
  });
};

export const updateWorkspace: (
  accessToken: string,
  workspaceId: string,
  name: string
) => Promise<WorkspaceDto> = async (accessToken, workspaceId, name) => {
  return makeRequestAndReturnJSON({
    method: "PATCH",
    url: `/api/app/workspaces/${workspaceId}`,
    body: JSON.stringify({ name }),
    accessToken,
  });
};

export const deleteWorkspace: (
  accessToken: string,
  workspaceId: string
) => Promise<MessageResponse> = async (accessToken, workspaceId) => {
  return makeRequestAndReturnJSON({
    method: "DELETE",
    url: `/api/app/workspaces/${workspaceId}`,
    accessToken,
  });
};

export const getTemplates: (
  accessToken: string,
  workspaceId: string
) => Promise<TemplateDetailsResponse> = async (accessToken, workspaceId) => {
  return makeRequestAndReturnJSON({
    method: "GET",
    url: `/api/app/workspaces/${workspaceId}/templates`,
    accessToken,
  });
};

export const getTemplate: (
  accessToken: string,
  workspaceId: string,
  templateId: string
) => Promise<TemplateDetailsDto> = async (
  accessToken,
  workspaceId,
  templateId
) => {
  return makeRequestAndReturnJSON({
    method: "GET",
    url: `/api/app/workspaces/${workspaceId}/templates/${templateId}`,
    accessToken,
  });
};
export const deleteTemplate: (
  accessToken: string,
  workspaceId: string,
  templateId: string
) => Promise<MessageResponse> = async (
  accessToken,
  workspaceId,
  templateId
) => {
  return makeRequestAndReturnJSON({
    method: "DELETE",
    url: `/api/app/workspaces/${workspaceId}/templates/${templateId}`,
    accessToken,
  });
};

export const addTemplate: (
  accessToken: string,
  workspaceId: string,
  name: string,
  contentBase64: string
) => Promise<TemplateDetailsDto> = async (
  accessToken,
  workspaceId,
  name,
  contentBase64
) => {
  return makeRequestAndReturnJSON({
    method: "POST",
    url: `/api/app/workspaces/${workspaceId}/templates`,
    accessToken,
    body: JSON.stringify({ name, contentBase64 }),
  });
};

export const updateTemplate: (
  accessToken: string,
  workspaceId: string,
  templateId: string,
  name: string,
  contentBase64: string
) => Promise<TemplateDetailsDto> = async (
  accessToken,
  workspaceId,
  templateId,
  name,
  contentBase64
) => {
  return makeRequestAndReturnJSON({
    method: "PATCH",
    url: `/api/app/workspaces/${workspaceId}/templates/${templateId}`,
    accessToken,
    body: JSON.stringify({ name, contentBase64 }),
  });
};

export const getTemplateContent: (
  accessToken: string,
  workspaceId: string,
  templateId: string
) => Promise<TemplateContentDto> = async (
  accessToken,
  workspaceId,
  templateId
) => {
  return makeRequestAndReturnJSON({
    method: "GET",
    url: `/api/app/workspaces/${workspaceId}/templates/${templateId}/content`,
    accessToken,
  });
};

export const getApiKeys: (
  accessToken: string,
  workspaceId: string
) => Promise<ApiKeysResponse> = async (accessToken, workspaceId) => {
  return makeRequestAndReturnJSON({
    method: "GET",
    url: `/api/app/workspaces/${workspaceId}/apikeys`,
    accessToken,
  });
};

export const deleteApiKey: (
  accessToken: string,
  workspaceId: string,
  apiKeyId: string
) => Promise<MessageResponse> = async (accessToken, workspaceId, apiKeyId) => {
  return makeRequestAndReturnJSON({
    method: "DELETE",
    url: `/api/app/workspaces/${workspaceId}/apikeys/${apiKeyId}`,
    accessToken,
  });
};

export const addApiKey: (
  accessToken: string,
  workspaceId: string,
  name: string
) => Promise<ApiKeyDto> = async (accessToken, workspaceId, name) => {
  return makeRequestAndReturnJSON({
    method: "POST",
    url: `/api/app/workspaces/${workspaceId}/apikeys`,
    accessToken,
    body: JSON.stringify({ name }),
  });
};

export const getInvites: (
  accessToken: string,
  workspaceId: string
) => Promise<WorkspaceInvitesResponse> = async (accessToken, workspaceId) => {
  return makeRequestAndReturnJSON({
    method: "GET",
    url: `/api/app/workspaces/${workspaceId}/invites`,
    accessToken,
  });
};

export const deleteInvite: (
  accessToken: string,
  workspaceId: string,
  inviteId: string
) => Promise<MessageResponse> = async (accessToken, workspaceId, inviteId) => {
  return makeRequestAndReturnJSON({
    method: "DELETE",
    url: `/api/app/workspaces/${workspaceId}/invites/${inviteId}`,
    accessToken,
  });
};

export const createInvite: (
  accessToken: string,
  workspaceId: string,
  email: string
) => Promise<WorkspaceInviteDto> = async (accessToken, workspaceId, email) => {
  return makeRequestAndReturnJSON({
    method: "POST",
    url: `/api/app/workspaces/${workspaceId}/invites`,
    accessToken,
    body: JSON.stringify({ email }),
  });
};

export const resendInvite: (
  accessToken: string,
  workspaceId: string,
  inviteId: string
) => Promise<MessageResponse> = async (accessToken, workspaceId, inviteId) => {
  return makeRequestAndReturnJSON({
    method: "POST",
    url: `/api/app/workspaces/${workspaceId}/invites/${inviteId}/resend`,
    accessToken,
  });
};

export const getMembers: (
  accessToken: string,
  workspaceId: string
) => Promise<WorkspaceCustomersResponse> = async (accessToken, workspaceId) => {
  return makeRequestAndReturnJSON({
    method: "GET",
    url: `/api/app/workspaces/${workspaceId}/customers`,
    accessToken,
  });
};

export const deleteWorkspaceMember: (
  accessToken: string,
  workspaceId: string,
  customerId: string
) => Promise<MessageResponse> = async (
  accessToken,
  workspaceId,
  customerId
) => {
  return makeRequestAndReturnJSON({
    method: "DELETE",
    url: `/api/app/workspaces/${workspaceId}/customers/${customerId}`,
    accessToken,
  });
};

export const updateWorkspaceMember: (
  accessToken: string,
  workspaceId: string,
  customerId: string,
  roles: CustomerWorkspaceRole[]
) => Promise<MessageResponse> = async (
  accessToken,
  workspaceId,
  customerId,
  roles
) => {
  return makeRequestAndReturnJSON({
    method: "PATCH",
    url: `/api/app/workspaces/${workspaceId}/customers/${customerId}`,
    accessToken,
    body: JSON.stringify({ roles }),
  });
};

export const getActivities: (
  accessToken: string,
  workspaceId: string,
  page: number
) => Promise<ActivityLogResponse> = async (accessToken, workspaceId, page) => {
  return makeRequestAndReturnJSON({
    method: "GET",
    url: `/api/app/workspaces/${workspaceId}/activities?page=${page}`,
    accessToken,
  });
};

export const getAnalytics: (
  accessToken: string,
  workspaceId: string
) => Promise<AnalyticsResponse> = async (accessToken, workspaceId) => {
  return makeRequestAndReturnJSON({
    method: "GET",
    url: `/api/app/workspaces/${workspaceId}/analytics`,
    accessToken,
  });
};

export const generatePdf: (
  accessToken: string,
  templateBase64: string,
  parameters: any
) => Promise<Blob> = async (accessToken, templateBase64, parameters) => {
  return makeRequestAndReturnBlob({
    method: "POST",
    url: `/api/app/pdf`,
    accessToken,
    body: JSON.stringify({
      templateBase64,
      parameters,
    }),
  });
};

export const getSubscription: (
  accessToken: string,
  workspaceId: string
) => Promise<SubscriptionDto> = async (accessToken, workspaceId) => {
  return makeRequestAndReturnJSON({
    method: "GET",
    url: `/api/app/workspaces/${workspaceId}/subscription`,
    accessToken,
  });
};

export const updateSubscription: (
  accessToken: string,
  workspaceId: string,
  productCode: ProductCode,
  billingPeriod: BillingPeriod
) => Promise<SubscriptionDto> = async (
  accessToken,
  workspaceId,
  productCode,
  billingPeriod
) => {
  return makeRequestAndReturnJSON({
    method: "PATCH",
    url: `/api/app/workspaces/${workspaceId}/subscription`,
    accessToken,
    body: JSON.stringify({
      productCode,
      billingPeriod,
    }),
  });
};
