import { ActivityDto, PdfGenerationSource } from "../../../api/types";
import { formatBytes } from "../../../utils/ByteUtils";
import { Format, formatEpochSeconds } from "../../../utils/TimeUtils";
import Badge from "../layout/utils/Badge";
import Card, { CardAction } from "../layout/utils/Card";
import Icon from "../layout/utils/Icon";
import Tooltip from "../layout/utils/Tooltip";

type ActivitiesTableProps = {
  activities: ActivityDto[];
  previousPageDisabled: boolean;
  nextPageDisabled: boolean;
  onPreviousPageClick: () => void;
  onNextPageClick: () => void;
};

export function ActivitiesTable({
  activities,
  previousPageDisabled,
  nextPageDisabled,
  onPreviousPageClick,
  onNextPageClick,
}: ActivitiesTableProps) {
  function getSourceBadge(source: PdfGenerationSource) {
    let bgColor: "green" | "blue" =
      source == PdfGenerationSource.API ? "green" : "blue";

    let sourceString = source.replace("_", " ");

    return <Badge color={bgColor}>{sourceString}</Badge>;
  }

  function getCardActions(): CardAction[] {
    return [
      {
        iconClass: "bi-arrow-left",
        buttonColor: "white-gray-border",
        disabled: previousPageDisabled,
        handler: onPreviousPageClick,
      },
      {
        iconClass: "bi-arrow-right",
        buttonColor: "white-gray-border",
        disabled: nextPageDisabled,
        className: "ml-2",
        handler: onNextPageClick,
      },
    ];
  }

  return (
    <Card title='Last activities' cardActions={getCardActions()}>
      <div className='p-3'>
        <div className='overflow-x-auto'>
          <table className='table-auto w-full'>
            {/* Table header */}
            <thead className='text-xs uppercase text-slate-400 bg-slate-50 rounded-sm'>
              <tr>
                <th className='p-2'>
                  <div className='font-semibold text-left'>
                    Date
                    <Icon className='bi-caret-down-fill ml-1' size='base' />
                  </div>
                </th>
                <th className='p-2'>
                  <div className='font-semibold text-left'>Template</div>
                </th>
                <th className='p-2'>
                  <div className='font-semibold text-left'>Source</div>
                </th>
                <th className='p-2'>
                  <div className='font-semibold text-left'>Generation time</div>
                </th>
                <th className='p-2'>
                  <div className='font-semibold text-left'>Size</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className='text-sm font-medium divide-y divide-slate-100'>
              {/* Row */}
              {activities.map((activity: ActivityDto) => (
                <tr key={activity.id}>
                  <td className='p-2 min-h-[2.5rem] inline-block'>
                    {formatEpochSeconds(activity.pdfCreatedAt, Format.LONG)}
                  </td>
                  <td className='p-2'>
                    {activity.templateName || "(deleted)"}
                  </td>
                  <td className='p-2 whitespace-nowrap'>
                    {getSourceBadge(activity.source)}
                  </td>
                  <td className='p-2'>{activity.generationTimeInMs} ms</td>
                  <td className='p-2'>
                    <Tooltip
                      text={activity.resultSizeInBytes + " bytes"}
                      delayMs={0}
                    >
                      {formatBytes(activity.resultSizeInBytes)}
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
}
