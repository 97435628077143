import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { workspacesRequested } from "../../../store/actions";
import { IRootState } from "../../../store/store";
import { getAccessToken } from "../../../utils/AuthUtils";
import Header from "../app/Header";
import Sidebar from "./Sidebar";
import { PageLoader } from "./utils/PageLoader";

interface ApplicationLayoutProperties {
  children: React.ReactNode;
}

export const ApplicationLayout = ({
  children,
}: ApplicationLayoutProperties) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarExpanded, setSidebarExpanded] = useState<boolean | null>(null);
  const workspaces = useSelector((state: IRootState) => state.workspaces);
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const accessToken = getAccessToken();

  useEffect(() => {
    if (workspaces && workspaces.length == 0) {
      navigate({
        pathname: "/create-workspace",
      });
    }
  }, [navigate, workspaces]);

  useEffect(() => {
    if (accessToken) {
      dispatch(workspacesRequested(accessToken));
    }
  }, []);

  useEffect(() => {
    const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");

    setSidebarExpanded(
      storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
    );
  }, []);

  useEffect(() => {
    if (sidebarExpanded !== null) {
      localStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
    }
  }, [sidebarExpanded]);

  if (sidebarExpanded === null || !workspaces) {
    return <PageLoader />;
  }

  return (
    <>
      <div className='bg-slate-100 text-slate-600 font-inter antialiased tracking-tight'>
        <div className='flex h-screen overflow-hidden'>
          {/* Sidebar */}
          <Sidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            sidebarExpanded={sidebarExpanded}
            setSidebarExpanded={setSidebarExpanded}
          />

          <div className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden'>
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <main className='page-content-wrapper'>
              <div className='px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto'>
                {children}
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};
