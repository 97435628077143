import { createSlice } from "@reduxjs/toolkit";

import { CustomerWorkspaceDto } from "../api/types";
import { workspacesFetched } from "./actions";

type SliceState = CustomerWorkspaceDto[] | null;

export const counterSlice = createSlice({
  name: "workspaces",
  initialState: null as SliceState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(workspacesFetched, (_state, action: any) => {
        return action.payload;
      })
      .addDefaultCase(() => {});
  },
});

export default counterSlice.reducer;
