import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { ApplicationErrorBoundary } from "../../components/error/ApplicationErrorBoundary";
import { ReduxToastManager } from "../../components/layout/utils/Toast";
import { handleApplicationError } from "../../utils/handleApplicationError";

const MainContainer = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [pathname]);

  return (
    <>
      <ApplicationErrorBoundary errorCallback={handleApplicationError}>
        <Outlet />

        <ReduxToastManager />
      </ApplicationErrorBoundary>
    </>
  );
};

export default MainContainer;
