export function PageLoader() {
  return (
    <div className='absolute top-0 left-0 h-full w-full'>
      <div
        className='relative top-1/2 left-1/2 animate-spin inline-block w-12 h-12 border-[3px] border-current border-t-transparent text-slate-500 rounded-full'
        role='status'
        aria-label='loading'
      ></div>
      <span className='sr-only'>Loading...</span>
    </div>
  );
}
