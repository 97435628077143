export function getLandingUrl() {
  return import.meta.env.VITE_LANDING_URL;
}

export function getAppUrl() {
  return import.meta.env.VITE_APP_URL;
}

export function getLoginUrl(): string {
  return getAppUrl() + "/login";
}

export function getRegisterUrl(): string {
  return getAppUrl() + "/register";
}

export function getDocumentationUrl(): string {
  return "https://dynamopdf.readme.io/";
}

export function getTermsUrl(): string {
  return "/terms";
}

export function getPrivacyPolicyUrl(): string {
  return "/privacy";
}

export function getCookiePolicyUrl(): string {
  return "/cookies";
}

export function getContactUrl(): string {
  return "/contact";
}

export function getPricingUrl(): string {
  return "/pricing";
}

export function getStatusPageUrl(): string {
  return "https://status.dynamopdf.com/";
}
