import { createSlice } from "@reduxjs/toolkit";

import { CustomerWorkspaceDto } from "../api/types";
import { getAccessToken, parseJwt } from "../utils/AuthUtils";
import { ACTIVE_CUSTOMER_WORKSPACE } from "../utils/Constants";
import {
  activeWorkspaceChanged,
  workspaceCreated,
  workspaceDeleted,
  workspacesFetched,
} from "./actions";

type ActiveWorkspaceSliceState = CustomerWorkspaceDto | null;

function findWorkspaceById(
  customerWorkspaces: CustomerWorkspaceDto[],
  id: string
): CustomerWorkspaceDto | null {
  return (
    customerWorkspaces.find(
      (customerWorkspace: CustomerWorkspaceDto) =>
        customerWorkspace.workspace.id === id
    ) || null
  );
}

function getStateFromLocalStorage(): ActiveWorkspaceSliceState {
  let accessToken = getAccessToken();
  if (!accessToken) return null;

  let username = parseJwt(accessToken).sub;
  let activeCustomerWorkspace = localStorage.getItem(
    username + ":" + ACTIVE_CUSTOMER_WORKSPACE
  );

  if (activeCustomerWorkspace == null) return null;

  return JSON.parse(activeCustomerWorkspace);
}

const counterSlice = createSlice({
  name: "workspaces",
  initialState: getStateFromLocalStorage(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(workspacesFetched, (state, action: any) => {
        let customerWorkspacesFromBE = action.payload;

        if (customerWorkspacesFromBE.length == 0) {
          return null;
        } else {
          if (state === null) {
            return customerWorkspacesFromBE[0];
          } else {
            let activeWorkspaceFromBE = findWorkspaceById(
              customerWorkspacesFromBE,
              state.workspace.id
            );

            if (activeWorkspaceFromBE) {
              return state;
            } else {
              return customerWorkspacesFromBE[0];
            }
          }
        }
      })
      .addCase(workspaceDeleted, (state, action: any) => {
        if (action.payload === state?.workspace.id) {
          return null;
        } else {
          return state;
        }
      })
      .addCase(workspaceCreated, (_state, action: any) => {
        return action.payload;
      })
      .addCase(activeWorkspaceChanged, (_state, action) => {
        return action.payload;
      })
      .addDefaultCase(() => {});
  },
});

export default counterSlice.reducer;
