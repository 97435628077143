import { ComponentPropsWithoutRef } from "react";

import Icon, { IconSize } from "./Icon";

export type ButtonColor =
  | "primary"
  | "secondary"
  | "black"
  | "alert"
  | "success"
  | "white-secondary-border"
  | "white-gray-border"
  | "transparent";
export type ButtonSize = "small" | "regular" | "large";
export type ButtonWidth = "min" | "max";

function getBgStyle(color: ButtonColor, disabled: boolean) {
  switch (color) {
    case "primary":
      if (disabled) {
        return "bg-indigo-500 " + "border-transparent " + "text-white ";
      } else {
        return (
          "bg-indigo-500 hover:bg-indigo-600 " +
          "border-transparent " +
          "text-white " +
          "focus:outline-none focus:ring-indigo-400 focus:ring-2 focus:ring-offset-2"
        );
      }
    case "secondary":
      return (
        "bg-blue-600 hover:bg-blue-700 " +
        "border-transparent " +
        "text-white " +
        "focus:outline-none focus:ring-blue-500 focus:ring-2 focus:ring-offset-2"
      );
    case "black":
      return (
        "bg-gray-900 hover:bg-gray-800 " +
        "border-transparent " +
        "text-white " +
        "focus:outline-none focus:ring-gray-500 focus:ring-2 focus:ring-offset-2"
      );
    case "alert":
      return (
        "bg-red-600 hover:bg-red-700 " +
        "border-transparent " +
        "text-white " +
        "focus:outline-none focus:ring-red-500 focus:ring-2 focus:ring-offset-2"
      );
    case "success":
      return (
        "bg-green-500 hover:bg-green-600 " +
        "border-transparent " +
        "text-white " +
        "focus:outline-none focus:ring-green-400 focus:ring-2 focus:ring-offset-2"
      );
    case "white-secondary-border":
      return (
        "bg-white hover:bg-gray-100 " +
        "border-blue-600 " +
        "text-blue-600 " +
        "focus:outline-none focus:ring-blue-500 focus:ring-2 focus:ring-offset-2"
      );
    case "white-gray-border":
      if (disabled) {
        return "bg-white " + "border-gray-300 " + "text-gray-300 ";
      } else {
        return (
          "bg-white hover:bg-gray-100 " +
          "border-gray-500 " +
          "text-gray-500 " +
          "focus:outline-none focus:ring-gray-400 focus:ring-2 focus:ring-offset-2"
        );
      }
    case "transparent":
      return (
        "" +
        "border-transparent " +
        "text-inherit " +
        "focus:outline-none focus:ring-2 focus:ring-offset-2"
      );
    default:
      return "";
  }
}

function getSizeStyle(size: ButtonSize) {
  if (size == "large") {
    return "px-8 py-3";
  } else if (size == "regular") {
    return "px-4 py-2";
  } else if (size == "small") {
    return "px-1.5 py-0.5";
  } else {
    return "";
  }
}

function getWidthStype(width: ButtonWidth) {
  if (width == "max") {
    return "w-full";
  } else {
    return "";
  }
}

interface ButtonProps extends ComponentPropsWithoutRef<"button"> {
  color?: ButtonColor;
  size?: ButtonSize;
  text?: string;
  iconClass?: string;
  iconSize?: IconSize;
  width?: ButtonWidth;
  disabled?: boolean;
  className?: string;
  loading?: boolean;
}

function Button({
  color = "primary",
  size = "regular",
  text,
  iconClass,
  iconSize,
  width = "min",
  disabled = false,
  className,
  loading = false,
  ...rest
}: ButtonProps) {
  return (
    <button
      disabled={disabled || loading}
      className={`${className || ""} ${
        disabled || loading ? "disabled" : ""
      } font-medium border rounded leading-snug
        ${getSizeStyle(size)} ${getBgStyle(
        color,
        disabled || loading
      )} ${getWidthStype(width)}`}
      {...rest}
    >
      {loading ? <Loading /> : ""}

      {!loading && iconClass ? (
        <Icon className={iconClass} size={iconSize} />
      ) : (
        ""
      )}

      {!loading && text ? (
        <span className={`xs:block ${iconClass ? "ml-2" : ""}`}>{text}</span>
      ) : (
        ""
      )}
    </button>
  );
}

export default Button;

const Loading = () => {
  return (
    <div
      className='relative -mt-4 top-[0.3rem] animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-white rounded-full'
      role='status'
      aria-label='loading'
    >
      <span className='sr-only'>Loading...</span>
    </div>
  );
};
