import { createSlice } from "@reduxjs/toolkit";

import { ToastType } from "../src/components/layout/utils/Toast";
import { toastClosed, toastRequested } from "./actions";

type SliceState = {
  message: string;
  type: ToastType;
} | null;

export const toastSlice = createSlice({
  name: "toast",
  initialState: null as SliceState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(toastRequested, (_state, action: any) => {
        if (!action.payload.message) return null; // TODO Hacky way of not showing toast without message

        return { message: action.payload.message, type: action.payload.type };
      })
      .addCase(toastClosed, () => {
        return null;
      })
      .addDefaultCase(() => {});
  },
});

export default toastSlice.reducer;
