import moment from "moment";

export enum Format {
  SHORT,
  LONG,
  MONTH_YEAR,
}

export function formatEpochSeconds(seconds: number, format: Format): string {
  let time = moment(seconds * 1000);

  if (format == Format.SHORT) {
    return time.format("YYYY-MM-DD");
  } else if (format == Format.LONG) {
    return time.format("YYYY-MM-DD HH:mm:ss (UTC Z)");
  } else if (format === Format.MONTH_YEAR) {
    return time.format("MMM YYYY");
  } else {
    return "";
  }
}
