import "chartjs-adapter-moment";

import {
  ArcElement,
  Chart,
  DoughnutController,
  TimeScale,
  Tooltip,
} from "chart.js";
import { useEffect, useRef } from "react";

import Card from "../layout/utils/Card";

Chart.register(DoughnutController, ArcElement, TimeScale, Tooltip);

type UsageCardProps = {
  monthlyUsageLimit: number;
  currentMonthUsage: number;
};

function UsageCard({ monthlyUsageLimit, currentMonthUsage }: UsageCardProps) {
  const canvas = useRef(null);

  const chartData = {
    labels: ["Used", "Unused"],
    datasets: [
      {
        data: [currentMonthUsage, calcUsageLeft()],
        backgroundColor: ["#6366f1", "#e2e8f0"],
        hoverBackgroundColor: ["#818cf8", "#e2e8f0"],
      },
    ],
  };

  useEffect(() => {
    const ctx = canvas.current;
    if (!ctx) return;

    const newChart = new Chart(ctx, {
      type: "doughnut",
      data: chartData,
      options: {
        cutout: "80%",
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (context) => " " + context.parsed,
            },
          },
        },
        interaction: {
          intersect: false,
          mode: "nearest",
        },
        animation: {
          duration: 500,
        },
        maintainAspectRatio: false,
      },
    });
    return () => newChart.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function calcUsageLeft() {
    return monthlyUsageLimit - currentMonthUsage;
  }

  function calcUsagePercent() {
    return (
      Math.round((currentMonthUsage / monthlyUsageLimit) * 100 * 100) / 100
    );
  }

  return (
    <Card title='Current month usage'>
      <div className='relative'>
        <canvas ref={canvas} width={595} height={248}></canvas>
        <div className='text-lg font-semobold absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2'>
          {calcUsagePercent()}%
        </div>
      </div>
    </Card>
  );
}

export default UsageCard;
