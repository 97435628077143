export enum ErrorCode {
  // 400 Bad Request
  INVALID_REQUEST = "INVALID_REQUEST",
  USERNAME_ALREADY_IN_USE = "USERNAME_ALREADY_IN_USE",
  API_KEY_NAME_ALREADY_IN_USE = "API_KEY_NAME_ALREADY_IN_USE",
  TEMPLATE_NAME_ALREADY_IN_USE = "TEMPLATE_NAME_ALREADY_IN_USE",
  CUSTOMER_ALREADY_INVITED = "CUSTOMER_ALREADY_INVITED",
  EMAIL_NOT_VERIFIED = "EMAIL_NOT_VERIFIED",
  EMAIL_VERIFICATION_TOKEN_EXPIRED = "EMAIL_VERIFICATION_TOKEN_EXPIRED",
  EMAIL_ALREADY_VERIFIED = "EMAIL_ALREADY_VERIFIED",
  CANNOT_DELETE_LAST_ADMIN_CUSTOMER = "CANNOT_DELETE_LAST_ADMIN_CUSTOMER",
  REFRESH_TOKEN_NOT_FOUND = "REFRESH_TOKEN_NOT_FOUND",
  PASSWORD_RECOVERY_CHALLENGE_TOKEN_EXPIRED = "PASSWORD_RECOVERY_CHALLENGE_TOKEN_EXPIRED",
  INVALID_TEMPLATE = "INVALID_TEMPLATE",

  // 404 Not found
  NOT_FOUND = "NOT_FOUND",
  TEMPLATE_NOT_FOUND = "TEMPLATE_NOT_FOUND",

  // 403 Forbidden
  REFRESH_TOKEN_EXPIRED = "REFRESH_TOKEN_EXPIRED",

  // 401 Unauthorized
  BAD_CREDENTIALS = "BAD_CREDENTIALS",
  NO_ACCESS = "NO_ACCESS",

  // 500 Internal server error
  UNEXPECTED = "UNEXPECTED",

  // FE ERRORS
  EMPTY_TEMPLATE_NAME = "EMPTY_TEMPLATE_NAME",
  EMPTY_TEMPLATE_CONTENT = "EMPTY_TEMPLATE_CONTENT",
  EMPTY_API_KEY_NAME = "EMPTY_API_KEY_NAME",
  EMPTY_WORKSPACE_NAME = "EMPTY_WORKSPACE_NAME",
  EMPTY_PASSWORD = "EMPTY_PASSWORD",
  INVALID_EMAIL = "INVALID_EMAIL",
  INVALID_TEMPLATE_PARAMETERS = "INVALID_TEMPLATE_PARAMETERS",
  PASSWORD_NOT_STRONG_ENOUGH = "PASSWORD_NOT_STRONG_ENOUGH",
}

export type ErrorResponse = {
  errorCode: ErrorCode;
  correlationId: string;
  data?: any;
};

export type MessageResponse = {
  message: string;
};

export type LoginResponse = {
  accessToken: string;
  refreshToken: string;
  roles: string[];
};
export type RefreshResponse = {
  accessToken: string;
  refreshToken: string;
  roles: string[];
};

export type CustomerWorkspacesResponse = {
  workspaces: CustomerWorkspaceDto[];
};

export type CustomerWorkspaceDto = {
  roles: CustomerWorkspaceRole[];
  workspace: WorkspaceDto;
};

export type WorkspaceDto = {
  id: string;
  name: string;
};

export enum CustomerWorkspaceRole {
  ADMIN = "ADMIN",
  USER = "USER",
}

export type TemplateDetailsResponse = {
  templateDetails: TemplateDetailsLiteDto[];
};

export type TemplateDetailsLiteDto = {
  id: string;
  name: string;
  createdAt: number;
  updatedAt: number;
};

export type TemplateDetailsDto = {
  id: string;
  name: string;
  createdAt: number;
  updatedAt: number;
  templateBase64: string;
};

export type TemplateContentDto = {
  contentBase64: string;
};

export type ApiKeysResponse = {
  apiKeys: ApiKeyDto[];
};

export type ApiKeyDto = {
  id: string;
  createdAt: number;
  name: string;
  apiKey: string;
  authorCustomerId: string;
};

export type WorkspaceInvitesResponse = {
  invites: WorkspaceInviteDto[];
};

export type WorkspaceInviteDto = {
  invite: InviteDto;
  workspace: WorkspaceDto;
};

export type InviteDto = {
  id: string;
  createdAt: number;
  email: string;
};

export type WorkspaceCustomersResponse = {
  customers: WorkspaceCustomerDto[];
};

export type WorkspaceCustomerDto = {
  createdAt: number;
  roles: CustomerWorkspaceRole[];
  customer: CustomerDto;
};

export type CustomerDto = {
  id: string;
  username: string;
};

export enum PdfGenerationSource {
  CUSTOMER_PORTAL = "CUSTOMER_PORTAL",
  API = "API",
}

export type ActivityDto = {
  id: string;
  pdfCreatedAt: number;
  templateName: string;
  source: PdfGenerationSource;
  generationTimeInMs: number;
  resultSizeInBytes: number;
};

export type ActivityLogResponse = {
  activities: ActivityDto[];
  hasNextPage: boolean;
};

export type OverallAnalyticsResponse = {
  totalUsage: number;
  last6MonthsUsage: number;
  currentMonthUsage: number;
};

export type MonthlyUsageDto = {
  month: string;
  year: number;
  usage: number;
};

export type AnalyticsResponse = {
  overallAnalytics: OverallAnalyticsResponse;
  monthlyUsages: MonthlyUsageDto[];
};

export enum ProductCode {
  FREE = "FREE",
  STARTER = "STARTER",
  STANDARD = "STANDARD",
  PRO = "PRO",
}

export type ProductDto = {
  id: string;
  code: ProductCode;
  monthlyPrice: number;
  yearlyPrice: number;
  monthlyUsageLimit: number;
};

export type ProductsResponse = {
  products: ProductDto[];
};

export enum BillingPeriod {
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

export type SubscriptionDto = {
  billingPeriod: BillingPeriod;
  product: ProductDto;
  freeTrialExpiration: number;
};
