import { makeRequestAndReturnJSON } from "./common";
import { MessageResponse, WorkspaceInviteDto } from "./types";

export const approveInvite: (
  accessToken: string,
  inviteId: string
) => Promise<MessageResponse> = async (accessToken, inviteId) => {
  return makeRequestAndReturnJSON({
    method: "PATCH",
    url: `/api/app/invites/${inviteId}`,
    accessToken,
    body: JSON.stringify({ decision: "APPROVE" }),
  });
};

export const getInviteDetails: (
  inviteId: string
) => Promise<WorkspaceInviteDto> = async (inviteId) => {
  return makeRequestAndReturnJSON({
    method: "GET",
    url: `/api/app/invites/${inviteId}`,
  });
};
