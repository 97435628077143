import { ReactNode } from "react";

type BadgeColor = "blue" | "green";

type BadgeProps = {
  color: BadgeColor;
  children: ReactNode;
};

function getBgStyle(color: BadgeColor) {
  switch (color) {
    case "blue":
      return "bg-blue-100 text-blue-800";
    case "green":
      return "bg-green-100 text-green-800";
    default:
      return "";
  }
}

function Badge({ color, children }: BadgeProps) {
  return (
    <span className={`py-0.5 px-1.5 rounded font-medium ${getBgStyle(color)}`}>
      {children}
    </span>
  );
}

export default Badge;
