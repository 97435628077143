import { createBrowserRouter, Navigate } from "react-router-dom";

import Account from "./pages/authenticated/account";
import ApiKeys from "./pages/authenticated/apiKeys";
import CreateEditTemplate from "./pages/authenticated/create-edit-template";
import CreateWorkspace from "./pages/authenticated/create-workspace";
import Dashboard from "./pages/authenticated/dashboard";
import Members from "./pages/authenticated/members";
import Settings from "./pages/authenticated/settings";
import Subscription from "./pages/authenticated/subscription";
import Templates from "./pages/authenticated/templates";
import { Page as Contact } from "./pages/contact.page";
import { Page as Cookies } from "./pages/cookies.page";
import { Page as Langing } from "./pages/index.page";
import JoinWorkspace from "./pages/open/join-workspace";
import Login from "./pages/open/login";
import OAuth2Redirect from "./pages/open/oauth2-redirect";
import PasswordRecoveryStart from "./pages/open/PasswordRecoveryStart";
import PasswordRecoverySubmit from "./pages/open/PasswordRecoverySubmit";
import Register from "./pages/open/register";
import VerifyEmail from "./pages/open/verify-email";
import VerifyEmailInfo from "./pages/open/verify-email-info";
import { Page as Pricing } from "./pages/pricing.page";
import { Page as Privacy } from "./pages/privacy.page";
import { Page as Terms } from "./pages/terms.page";
import { AuthContainer } from "./routes/AuthContainer";
import { HandleNotFound } from "./routes/utils/HandleNotFound";
import MainContainer from "./routes/utils/RoutesContainer";

export const router = createBrowserRouter([
  {
    element: <MainContainer />,
    /** Public routes */
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "oauth2/redirect",
        element: <OAuth2Redirect />,
      },
      {
        path: "verify-email-info",
        element: <VerifyEmailInfo />,
      },
      {
        path: "verify-email",
        element: <VerifyEmail />,
      },
      {
        path: "join-workspace",
        element: <JoinWorkspace />,
      },
      {
        path: "password-recovery/start",
        element: <PasswordRecoveryStart />,
      },
      {
        path: "password-recovery/submit",
        element: <PasswordRecoverySubmit />,
      },
      import.meta.env.DEV && {
        path: "landing",
        element: <Langing />,
      },
      import.meta.env.DEV && {
        path: "terms",
        element: <Terms />,
      },
      import.meta.env.DEV && {
        path: "privacy",
        element: <Privacy />,
      },
      import.meta.env.DEV && {
        path: "cookies",
        element: <Cookies />,
      },
      import.meta.env.DEV && {
        path: "contact",
        element: <Contact />,
      },
      import.meta.env.DEV && {
        path: "pricing",
        element: <Pricing />,
      },
      /** Secured routes */
      {
        element: <AuthContainer wrappedToApplicationLayout={true} />,
        children: [
          {
            path: "",
            element: <Navigate to='/dashboard' />,
          },
          {
            path: "dashboard",
            element: <Dashboard />,
          },
          {
            path: "templates",
            element: <Templates />,
          },
          {
            path: "templates/create-edit",
            element: <CreateEditTemplate />,
          },
          {
            path: "api-keys",
            element: <ApiKeys />,
          },
          {
            path: "members",
            element: <Members />,
          },
          {
            path: "subscription",
            element: <Subscription />,
          },
          {
            path: "settings",
            element: <Settings />,
          },
          {
            path: "account",
            element: <Account />,
          },
        ],
      },
      {
        element: <AuthContainer wrappedToApplicationLayout={false} />,
        children: [
          {
            path: "create-workspace",
            element: <CreateWorkspace />,
          },
        ],
      },
      // Catch not found paths
      {
        path: "*",
        element: <HandleNotFound />,
      },
    ],
  },
]);
