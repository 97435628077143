import "../src/styles/css/style.css";

import React from "react";
// https://react.dev/reference/react/StrictMode
/*
Strict Mode enables the following development-only behaviors:

Your components will re-render an extra time to find bugs caused by impure rendering.
Your components will re-run Effects an extra time to find bugs caused by missing Effect cleanup.
Your components will be checked for usage of deprecated APIs.
*/
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import { Providers } from "../store/provider";
import { ApplicationErrorBoundary } from "./components/error/ApplicationErrorBoundary";
import { router } from "./router";
import { handleApplicationError } from "./utils/handleApplicationError";
import { SentryInitialiser } from "./utils/SentryInitialiser";

createRoot(document.getElementById("root") as HTMLDivElement).render(
  <React.StrictMode>
    <ApplicationErrorBoundary errorCallback={handleApplicationError}>
      <Providers>
        <RouterProvider router={router} />

        <SentryInitialiser />
      </Providers>
    </ApplicationErrorBoundary>
  </React.StrictMode>
);
