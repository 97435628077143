import "chartjs-adapter-moment";

import {
  BarController,
  BarElement,
  Chart,
  Legend,
  LinearScale,
  TimeScale,
  Tooltip,
} from "chart.js";
import { useEffect, useRef } from "react";

import { MonthlyUsageDto } from "../../../api/types";
import { capitalizeFirstLetter } from "../../../utils/StringUtils";
import { Format, formatEpochSeconds } from "../../../utils/TimeUtils";
import Card from "../layout/utils/Card";

Chart.register(
  BarController,
  BarElement,
  LinearScale,
  TimeScale,
  Tooltip,
  Legend
);

function getMonthNumberFromName(monthName: string) {
  const year = new Date().getFullYear();
  return new Date(`${monthName} 1, ${year}`).getMonth() + 1;
}

function AnalyticsCard({
  monthlyUsages,
}: {
  monthlyUsages: MonthlyUsageDto[];
}) {
  const canvas = useRef(null);

  const chartData = {
    labels: monthlyUsages.map(
      (monthlyUsage: MonthlyUsageDto) =>
        "01-" +
        getMonthNumberFromName(capitalizeFirstLetter(monthlyUsage.month)) +
        "-" +
        monthlyUsage.year
    ),
    datasets: [
      {
        data: monthlyUsages.map(
          (monthlyUsage: MonthlyUsageDto) => monthlyUsage.usage || 0
        ),
        backgroundColor: "#6366f1",
        hoverBackgroundColor: "#818cf8",
        barPercentage: 0.66,
        categoryPercentage: 0.66,
      },
    ],
  };

  useEffect(() => {
    const ctx = canvas.current;
    if (!ctx) return;

    const newChart = new Chart(ctx, {
      type: "bar",
      data: chartData,
      options: {
        scales: {
          y: {
            border: {
              display: false,
            },
            ticks: {
              maxTicksLimit: 5,
              callback: (value) => value,
            },
          },
          x: {
            type: "time",
            time: {
              parser: "DD-MM-YYYY",
              unit: "month",
              displayFormats: {
                month: "MMM YY",
              },
            },
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: (a) =>
                formatEpochSeconds(a[0].parsed.x / 1000, Format.MONTH_YEAR),
              label: (context) => " " + (context.parsed.y || "0"),
            },
          },
        },
        interaction: {
          intersect: false,
          mode: "nearest",
        },
        animation: {
          duration: 500,
        },
        maintainAspectRatio: false,
      },
    });
    return () => newChart.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card title='Monthly usage'>
      <canvas ref={canvas} width={595} height={248}></canvas>
    </Card>
  );
}

export default AnalyticsCard;
