import { ComponentPropsWithoutRef, ReactNode, useEffect, useRef } from "react";

import Button from "./Button";
import Icon from "./Icon";
import Transition from "./Transition";

interface ModalProps extends ComponentPropsWithoutRef<"div"> {
  id: any;
  modalOpen: boolean;
  setModalClosed: () => void;
  children: ReactNode;
  className?: string;
  isOnTop?: boolean;
  title: string;
}

function Modal({
  id,
  modalOpen,
  setModalClosed,
  children,
  className,
  isOnTop = true,
  title,
}: ModalProps) {
  const modalContent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (
        !isOnTop ||
        !modalOpen ||
        (target instanceof HTMLElement &&
          modalContent.current?.contains(target))
      )
        return;
      setModalClosed();
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: { keyCode: number }) => {
      if (!isOnTop || !modalOpen || keyCode !== 27) return;
      setModalClosed();
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div>
      {/* Modal backdrop */}
      <Transition
        className='fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity'
        show={modalOpen}
        enter='transition ease-out duration-200'
        enterStart='opacity-0'
        enterEnd='opacity-100'
        leave='transition ease-out duration-100'
        leaveStart='opacity-100'
        leaveEnd='opacity-0'
        aria-hidden='true'
        appear={true}
      />
      {/* Modal dialog */}
      <Transition
        id={id}
        className='fixed inset-0 z-50 overflow-hidden flex items-start top-20 mb-4 justify-center px-4 sm:px-6'
        role='dialog'
        aria-modal='true'
        show={modalOpen}
        enter='transition ease-in-out duration-200'
        enterStart='opacity-0 translate-y-4'
        enterEnd='opacity-100 translate-y-0'
        leave='transition ease-in-out duration-200'
        leaveStart='opacity-100 translate-y-0'
        leaveEnd='opacity-0 translate-y-4'
        appear={true}
      >
        <div
          ref={modalContent}
          className={`bg-white border border-transparent overflow-auto max-h-full rounded shadow-lg ${className}`}
        >
          <div className='p-5'>
            <div className='flex'>
              <h3 className='h4 w-full mr-4'>{title}</h3>
              <Button
                className='p-1'
                color='transparent'
                iconClass='bi-x-lg text-lg'
                size='small'
                onClick={() => setTimeout(() => setModalClosed())}
              >
                <Icon className='bi-x-lg' size='lg' />
              </Button>
            </div>
            <div className='mt-5'>{children}</div>
          </div>
        </div>
      </Transition>
    </div>
  );
}

export default Modal;
