import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { CustomerWorkspaceDto } from "../../../../api/types";
import { activeWorkspaceChanged } from "../../../../store/actions";
import { IRootState } from "../../../../store/store";
import Transition from "../../../components/layout/utils/Transition";

function DropdownWorkspaces({
  sidebarExpanded,
  sidebarOpen,
  setSidebarOpen,
}: {
  sidebarExpanded: boolean;
  sidebarOpen: boolean;
  setSidebarOpen: (newValue: boolean) => void;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);

  const workspaces = useSelector((state: IRootState) => state.workspaces);

  const activeCustomerWorkspace = useSelector(
    (state: IRootState) => state.activeWorkspace
  );

  const trigger = useRef<HTMLButtonElement>(null);
  const dropdown = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        (target instanceof HTMLElement && dropdown.current.contains(target)) ||
        (target instanceof HTMLElement && trigger.current?.contains(target))
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ key }: KeyboardEvent) => {
      if (!dropdownOpen || key !== "Escape") return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  function selectNewCustomerWorkspace(
    customerWorkspace: CustomerWorkspaceDto
  ): void {
    dispatch(activeWorkspaceChanged(customerWorkspace));
    setDropdownOpen(false);
    setSidebarOpen(false);
  }

  function getNotActiveWorkspaces(): CustomerWorkspaceDto[] {
    if (!workspaces || !activeCustomerWorkspace) return [];

    return workspaces.filter(
      (customerWorkspace: CustomerWorkspaceDto) =>
        customerWorkspace.workspace.id !== activeCustomerWorkspace.workspace?.id
    );
  }

  function handleCreateNewWorkspace(): void {
    navigate("/create-workspace");
  }

  if (!workspaces || !activeCustomerWorkspace) return <></>;

  return (
    <div className='relative block lg:inline-block'>
      <button
        ref={trigger}
        className='inline-flex justify-center items-center group'
        aria-haspopup='true'
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
        onMouseEnter={() => setHasFocus(true)}
        onMouseLeave={() => setHasFocus(false)}
      >
        <svg className='shrink-0 h-6 w-6' viewBox='0 0 24 24'>
          <path
            className='fill-current text-slate-600'
            d='M20 7a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 0120 7zM4 23a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 014 23z'
          />
          <path
            className='fill-current text-slate-400'
            d='M17 23a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 010-2 4 4 0 004-4 1 1 0 012 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1zM7 13a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 110-2 4 4 0 004-4 1 1 0 112 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1z'
          />
        </svg>
        <span
          className={`truncate ml-2 text-sm font-medium text-slate-200 2xl:inline ${
            hasFocus ? "text-white" : ""
          } ${sidebarOpen || sidebarExpanded ? "visible" : "hidden"} 
            ${sidebarOpen && !sidebarExpanded ? "lg:hidden" : ""}`}
        >
          {activeCustomerWorkspace.workspace.name}
        </span>
        <svg
          className={`${
            !hasFocus ? "invisible" : ""
          } top-0 left-0 w-3 h-3 ml-1 mr-1 fill-current text-white`}
          viewBox='0 0 12 12'
        >
          <path d='M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z' />
        </svg>
      </button>

      <Transition
        className='origin-top-right z-10 absolute top-full min-w-44 bg-white border border-slate-200 rounded shadow-lg overflow-hidden mt-1 left-0 py-2'
        show={dropdownOpen}
        enter='transition ease-out duration-200 transform'
        enterStart='opacity-0 -translate-y-2'
        enterEnd='opacity-100 translate-y-0'
        leave='transition ease-out duration-200'
        leaveStart='opacity-100'
        leaveEnd='opacity-0'
        appear={true}
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          {getNotActiveWorkspaces().map(
            (customerWorkspace: CustomerWorkspaceDto) => (
              <div
                className='cursor-pointer font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1.5 px-3'
                key={customerWorkspace.workspace.id}
                onClick={() => selectNewCustomerWorkspace(customerWorkspace)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  className='bi bi-plus mr-1.5 w-4 h-4'
                  viewBox='0 0 16 16'
                >
                  <path d='M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z' />
                  <path d='M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z' />
                </svg>
                {customerWorkspace.workspace.name}
              </div>
            )
          )}
          <div
            className='cursor-pointer font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1.5 px-3'
            onClick={handleCreateNewWorkspace}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              className='bi bi-plus -ml-0.5 mr-1 w-5 h-5'
              viewBox='0 0 16 16'
            >
              <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
            </svg>
            New workspace
          </div>
        </div>
      </Transition>
    </div>
  );
}

export default DropdownWorkspaces;
