import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  BillingPeriod,
  ErrorResponse,
  ProductCode,
  SubscriptionDto,
} from "../../../api/types";
import { getSubscription, updateSubscription } from "../../../api/workspace";
import { toastRequested } from "../../../store/actions";
import { IRootState } from "../../../store/store";
import { getAccessToken } from "../../../utils/AuthUtils";
import { getErrorMessage } from "../../../utils/ErrorUtils";
import { capitalizeFirstLetter } from "../../../utils/StringUtils";
import { Format, formatEpochSeconds } from "../../../utils/TimeUtils";
import PricingPlans, { PlanDetails } from "../../components/app/PricingPlans";
import Badge from "../../components/layout/utils/Badge";
import Card from "../../components/layout/utils/Card";
import { PageLoader } from "../../components/layout/utils/PageLoader";

export default function Subscription() {
  const dispatch = useDispatch();
  const [subscription, setSubscription] = useState<SubscriptionDto | null>(
    null
  );
  const activeCustomerWorkspace = useSelector(
    (state: IRootState) => state.activeWorkspace
  );
  let accessToken = getAccessToken();

  useEffect(() => {
    if (!accessToken || !activeCustomerWorkspace) return;

    getSubscription(accessToken, activeCustomerWorkspace.workspace.id)
      .then((subscription: SubscriptionDto) => {
        setSubscription(subscription);
      })
      .catch((errorResponse: ErrorResponse) => {
        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      });
  }, [dispatch, activeCustomerWorkspace?.workspace.id]);

  function getPricingDetails(): string {
    if (!subscription) return "";

    if (subscription.billingPeriod == BillingPeriod.MONTHLY) {
      return "$" + subscription.product.monthlyPrice + " per month";
    } else if (subscription.billingPeriod == BillingPeriod.YEARLY) {
      return (
        "$" +
        subscription.product.yearlyPrice +
        " per year ($" +
        subscription.product.yearlyPrice / 12 +
        " per month)"
      );
    } else {
      return "";
    }
  }

  function handlePlanSelected(planDetails: PlanDetails): void {
    console.log(planDetails);

    if (!accessToken || !activeCustomerWorkspace) return;

    updateSubscription(
      accessToken,
      activeCustomerWorkspace.workspace.id,
      planDetails.productCode,
      planDetails.billingPeriod
    )
      .then((subscription: SubscriptionDto) => {
        setSubscription(subscription);
      })
      .catch((errorResponse: ErrorResponse) => {
        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      });
  }

  if (!activeCustomerWorkspace || !subscription) {
    return <PageLoader />;
  }
  return (
    <>
      <Card title='Subscription plan'>
        <table className='border-separate border-spacing-y-3 border-spacing-x-5 -mt-3 -ml-5'>
          <tbody>
            <tr>
              <td className='min-w-[10rem]'>
                <p className='text-sm font-semibold'>Plan</p>
              </td>
              <td>
                <Badge color='green'>
                  {capitalizeFirstLetter(subscription.product.code)}
                </Badge>
              </td>
            </tr>
            <tr>
              <td className='min-w-[10rem]'>
                <p className='text-sm font-semibold'>Monthly generations</p>
              </td>
              <td>
                <p>{subscription.product.monthlyUsageLimit.toLocaleString()}</p>
              </td>
            </tr>

            {subscription.product.code != ProductCode.FREE &&
            subscription.freeTrialExpiration ? (
              <tr>
                <td className='min-w-[10rem]'>
                  <p className='text-sm font-semibold'>Free trial until</p>
                </td>
                <td>
                  <p>
                    {formatEpochSeconds(
                      subscription.freeTrialExpiration,
                      Format.SHORT
                    )}
                  </p>
                </td>
              </tr>
            ) : (
              ""
            )}

            {subscription.product.code != ProductCode.FREE ? (
              <tr>
                <td className='min-w-[10rem]'>
                  <p className='text-sm font-semibold'>Price</p>
                </td>
                <td>
                  <p>{getPricingDetails()}</p>
                </td>
              </tr>
            ) : (
              ""
            )}
          </tbody>
        </table>
      </Card>

      {subscription.product.code != ProductCode.FREE ? (
        <Card title='Billing' className='mt-8'>
          <table className='border-separate border-spacing-y-3 border-spacing-x-5 -mt-3 -ml-5'>
            <tbody>
              <tr>
                <td className='min-w-[10rem]'>
                  <p className='text-sm font-semibold'>Billing period</p>
                </td>
                <td>
                  <span className='py-0.5 px-1.5 rounded font-medium bg-blue-100 text-blue-800'>
                    {capitalizeFirstLetter(subscription.billingPeriod)}
                  </span>
                </td>
              </tr>

              <tr>
                <td className='min-w-[10rem]'>
                  <p className='text-sm font-semibold mt-2'>Next invoice</p>
                </td>
                <td>
                  <p>
                    {formatEpochSeconds(
                      subscription.freeTrialExpiration,
                      Format.SHORT
                    )}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </Card>
      ) : (
        ""
      )}

      <Card title='Change subscription plan' className='mt-8'>
        <PricingPlans
          className='mt-0 mb-10'
          onPlanChanged={handlePlanSelected}
          buttonText='Select'
          currentPlanDetails={{
            billingPeriod: subscription.billingPeriod,
            productCode: subscription.product.code,
          }}
        />
      </Card>
    </>
  );
}
