"use client";
import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { getAccessToken, parseJwt } from "../utils/AuthUtils";
import { ACTIVE_CUSTOMER_WORKSPACE } from "../utils/Constants";
import activeWorkspaceSlice from "./activeWorkspaceSlice";
import toastSlice from "./toastSlice";
import workspacesSlice from "./workspacesSlice";

const rootReducer = combineReducers({
  workspaces: workspacesSlice,
  activeWorkspace: activeWorkspaceSlice,
  toast: toastSlice,
});

export const store = configureStore({
  reducer: rootReducer,
});

store.subscribe(() => {
  let accessToken = getAccessToken();
  if (!accessToken) return null;

  let username = parseJwt(accessToken).sub;

  let activeWorkspace = store.getState().activeWorkspace;

  if (activeWorkspace) {
    window.localStorage.setItem(
      username + ":" + ACTIVE_CUSTOMER_WORKSPACE,
      JSON.stringify(activeWorkspace)
    );
  } else {
    window.localStorage.removeItem(username + ":" + ACTIVE_CUSTOMER_WORKSPACE);
  }
});

export type IRootState = ReturnType<typeof rootReducer>;
