import { FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { submitPasswordRecoveryChallenge } from "../../../api/auth";
import { ErrorCode, ErrorResponse } from "../../../api/types";
import { toastRequested } from "../../../store/actions";
import { getErrorMessage } from "../../../utils/ErrorUtils";
import Button from "../../components/layout/utils/Button";

function PasswordRecoverySubmit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [challengeErrorCode, setChallengeErrorCode] =
    useState<ErrorCode | null>(null);
  const [newPassword, setNewPassword] = useState<string | null>(null);

  let email = searchParams.get("email");
  let token = searchParams.get("token");
  let expiry = searchParams.get("expiry");

  useEffect(() => {
    if (!email || !token || !expiry) {
      navigate("/");
    }
  }, [navigate, searchParams, email, token]);

  function handleResetPassword(e: FormEvent) {
    e.preventDefault();

    if (!email || !token || !newPassword) return;

    submitPasswordRecoveryChallenge(email, token, newPassword)
      .then(() => {
        dispatch(
          toastRequested({
            type: "success",
            message: "Password successfully set",
          })
        );
        setTimeout(() => {
          navigate({
            pathname: "/login",
          });
        }, 1000);
      })
      .catch((errorResponse: ErrorResponse) => {
        if (errorResponse.errorCode === ErrorCode.PASSWORD_NOT_STRONG_ENOUGH) {
          setChallengeErrorCode(errorResponse.errorCode);
        } else if (errorResponse.errorCode === ErrorCode.NOT_FOUND) {
          dispatch(
            toastRequested({
              type: "error",
              message: "Invalid or already used password recovery token",
            })
          );
        } else {
          dispatch(
            toastRequested({
              type: "error",
              message: getErrorMessage(errorResponse),
            })
          );
        }
      });
  }

  function isPasswordError() {
    return challengeErrorCode === ErrorCode.PASSWORD_NOT_STRONG_ENOUGH;
  }

  return (
    <section className='font-inter antialiased tracking-tight bg-gradient-to-b from-gray-100 to-white'>
      <div className='max-w-6xl mx-auto px-4 sm:px-6'>
        <div className='pt-32 pb-12 md:pt-60 md:pb-20'>
          {/* Page header */}
          <div className='max-w-3xl mx-auto text-center pb-12 md:pb-20'>
            <h1 className='h3'>Reset password</h1>
            <p className='text-xl text-gray-600 mb-8'>Choose a new password</p>
            <div className='w-full px-3'>
              <form onSubmit={handleResetPassword}>
                <div className='max-w-xs m-auto'>
                  <input
                    id='passwrod'
                    type='password'
                    className={`form-input w-full text-gray-800 ${
                      isPasswordError() ? "!border-rose-500" : ""
                    }`}
                    placeholder='Enter new password'
                    required
                    value={newPassword || ""}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
                <div className='mt-5'>
                  <Button
                    type='submit'
                    color='secondary'
                    size='large'
                    text='Reset password'
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PasswordRecoverySubmit;
