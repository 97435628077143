import { ResponseType } from "axios";

import { axiosAuth } from "../utils/AuthUtils";
import { ErrorCode, ErrorResponse } from "./types";

export const API_URL = import.meta.env.VITE_API_URL;

export type RequestParams = {
  method: string;
  url: string;
  body?: string;
  accessToken?: string;
};

export async function makeRequestAndReturnJSON<Type>(
  requestParams: RequestParams
): Promise<Type> {
  return makeRequest<Type>(requestParams, "json");
}

export async function makeRequestAndReturnBlob(
  requestParams: RequestParams
): Promise<Blob> {
  return makeRequest<Blob>(requestParams, "blob");
}

async function makeRequest<Type>(
  { method, url, body }: RequestParams,
  responseType: ResponseType
) {
  return axiosAuth({
    method,
    url: API_URL + url,
    data: body,
    responseType,
  })
    .then((response) => {
      return response.data as Type;
    })
    .catch(async (axiosError) => {
      let errorResponse;

      if (
        axiosError.request.responseType === "blob" &&
        axiosError.response?.data instanceof Blob &&
        axiosError.response?.data.type &&
        axiosError.response?.data.type.toLowerCase().indexOf("json") != -1
      ) {
        errorResponse = JSON.parse(
          await axiosError.response.data.text()
        ) as ErrorResponse;
      } else if (axiosError.response) {
        errorResponse = axiosError.response.data;
      } else {
        errorResponse = <ErrorResponse>{
          errorCode: ErrorCode.UNEXPECTED,
        };
      }

      throw errorResponse as ErrorResponse;
    });
}
