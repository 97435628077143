import { ReactNode } from "react";

import Button, { ButtonColor } from "./Button";

export type CardProps = {
  title: string;
  children: ReactNode;
  cardActions?: CardAction[];
  className?: string;
};

export type CardAction = {
  text?: string;
  iconClass?: string;
  buttonColor?: ButtonColor;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  handler: () => void;
};

function Card({ title, cardActions, children, className }: CardProps) {
  return (
    <>
      <div
        className={`col-span-full xl:col-span-8 max-w-6xl	mx-auto bg-white shadow-lg rounded-sm border border-slate-200 py-0.5 ${className}`}
      >
        <header className='flex justify-between items-center px-5 py-4 border-b border-slate-100'>
          <h2 className='inline-block font-semibold truncate'>{title}</h2>
          <div className='-my-2'>
            {cardActions
              ? cardActions.map((cardAction: CardAction) => (
                  <Button
                    key={
                      cardAction.text +
                      "" +
                      cardAction.iconClass +
                      cardAction.className
                    }
                    text={cardAction.text}
                    iconClass={cardAction.iconClass}
                    color={cardAction.buttonColor}
                    className={cardAction.className}
                    loading={cardAction.loading}
                    disabled={cardAction.disabled}
                    onClick={cardAction.handler}
                  />
                ))
              : ""}
          </div>
        </header>
        <div className='px-5 py-4'>{children}</div>
      </div>
    </>
  );
}

export default Card;
