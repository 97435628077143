import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  ErrorResponse,
  TemplateDetailsLiteDto,
  TemplateDetailsResponse,
} from "../../../api/types";
import { getTemplates } from "../../../api/workspace";
import { toastRequested } from "../../../store/actions";
import { IRootState } from "../../../store/store";
import { getAccessToken } from "../../../utils/AuthUtils";
import { getErrorMessage } from "../../../utils/ErrorUtils";
import { TemplatesTable } from "../../components/app/TemplatesTable";
import { PageLoader } from "../../components/layout/utils/PageLoader";

export default function Templates() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = getAccessToken();
  const [templates, setTemplates] = useState<TemplateDetailsLiteDto[]>();

  const activeCustomerWorkspace = useSelector(
    (state: IRootState) => state.activeWorkspace
  );

  const loadTemplates = useCallback(
    (accessToken: string, workspaceId: string) => {
      getTemplates(accessToken, workspaceId)
        .then((templateDetailsResponse: TemplateDetailsResponse) => {
          setTemplates(templateDetailsResponse.templateDetails);
        })
        .catch((errorResponse: ErrorResponse) => {
          dispatch(
            toastRequested({
              type: "error",
              message: getErrorMessage(errorResponse),
            })
          );
        });
    },
    []
  );

  useEffect(() => {
    if (accessToken && activeCustomerWorkspace) {
      loadTemplates(accessToken, activeCustomerWorkspace.workspace.id);
    }

    return () => {
      setTemplates(undefined);
    };
  }, [activeCustomerWorkspace?.workspace.id, loadTemplates]);

  function handleNewTemplateClick(): void {
    navigate("/templates/create-edit");
  }

  function handleEditTemplateClick(templateId: string): void {
    navigate(`/templates/create-edit?templateId=${templateId}`);
  }

  if (!activeCustomerWorkspace || !templates || !accessToken) {
    return <PageLoader />;
  }

  return (
    <>
      <TemplatesTable
        templates={templates}
        activeWorkspaceId={activeCustomerWorkspace.workspace.id}
        onTemplateReload={() =>
          loadTemplates(accessToken, activeCustomerWorkspace.workspace.id)
        }
        onNewTemplateClick={handleNewTemplateClick}
        onEditTemplateClick={handleEditTemplateClick}
      />
    </>
  );
}
