import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { deleteCustomer, udateCustomer } from "../../../api/auth";
import { ErrorCode, ErrorResponse } from "../../../api/types";
import { toastRequested } from "../../../store/actions";
import { getAccessToken, removeAuthData } from "../../../utils/AuthUtils";
import { getErrorMessage } from "../../../utils/ErrorUtils";
import Button from "../../components/layout/utils/Button";
import Card from "../../components/layout/utils/Card";
import Modal from "../../components/layout/utils/Modal";

function Account() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorCode, setErrorCode] = useState<ErrorCode | null>(null);
  const [newPassword, setNewPassword] = useState("");
  let accessToken = getAccessToken();
  const [deleteAccountModalOpen, setDeleteAccountModalOpen] =
    useState<boolean>(false);

  function handleUpdatePassword() {
    if (!accessToken) return;

    if (!newPassword) {
      setErrorCode(ErrorCode.EMPTY_PASSWORD);
      dispatch(
        toastRequested({
          type: "error",
          message: "Password cannot be empty",
        })
      );
      return;
    }

    udateCustomer(accessToken, newPassword)
      .then(() => {
        setErrorCode(null);
        dispatch(
          toastRequested({ type: "success", message: "Password changed" })
        );
        removeAuthData();
        navigate("/");
      })
      .catch((errorResponse: ErrorResponse) => {
        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      });
  }

  function handleDeleteAccount(event: React.FormEvent) {
    event.preventDefault();

    if (!accessToken) return;

    deleteCustomer(accessToken)
      .then(() => {
        removeAuthData();
        navigate("/");
      })
      .catch((errorResponse: ErrorResponse) => {
        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      });
  }

  function isPasswordError(): boolean {
    return errorCode === ErrorCode.EMPTY_PASSWORD;
  }

  if (!accessToken) return <></>;

  return (
    <div className='max-w-3xl mx-auto'>
      <Card title='Change password'>
        <div className='block'>
          <label
            className='block text-gray-800 text-sm font-medium mb-1'
            htmlFor='email'
          >
            New password <span className='text-red-600'>*</span>
          </label>
          <div className='block'>
            <input
              id='new-password'
              type='password'
              className={`form-input w-80 text-gray-800 ${
                isPasswordError() ? "!border-rose-500" : ""
              }`}
              placeholder='New password here'
              value={newPassword}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setNewPassword(event.target.value)
              }
            />
          </div>

          <div className='mt-3'>
            <Button
              type='button'
              color='primary'
              size='regular'
              onClick={handleUpdatePassword}
              text='Save'
            />
          </div>
        </div>
      </Card>

      <Card title='Delete account' className='mt-8'>
        <p>
          Delete permanently all the data associated to the account. This action
          is not reversible.
        </p>
        <div className='block mt-3'>
          <Button
            type='button'
            color='alert'
            size='regular'
            onClick={() => {
              setTimeout(() => setDeleteAccountModalOpen(true));
            }}
            text='Delete'
          />
        </div>
      </Card>

      <Modal
        id='delete-account-modal'
        title='Delete account'
        modalOpen={deleteAccountModalOpen}
        setModalClosed={() => setDeleteAccountModalOpen(false)}
      >
        <form onSubmit={handleDeleteAccount}>
          <div className='w-full mt-5'>
            <p className='mb-3 mr-5'>
              Are you sure? This action is not reversible
            </p>
            <Button
              type='submit'
              color='alert'
              text='Delete'
              size='large'
              width='max'
            />
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default Account;
