import { ErrorCode, ErrorResponse } from "../api/types";

export function getErrorMessage(formError: ErrorResponse): string {
  if (formError.errorCode === ErrorCode.BAD_CREDENTIALS) {
    return "Invalid username or password";
  } else if (formError.errorCode === ErrorCode.USERNAME_ALREADY_IN_USE) {
    return "Email already in use";
  } else if (formError.errorCode === ErrorCode.API_KEY_NAME_ALREADY_IN_USE) {
    return "API Key name already in use";
  } else if (formError.errorCode === ErrorCode.TEMPLATE_NAME_ALREADY_IN_USE) {
    return "Template name already in use";
  } else if (formError.errorCode === ErrorCode.TEMPLATE_NOT_FOUND) {
    return "Template not found";
  } else if (formError.errorCode === ErrorCode.CUSTOMER_ALREADY_INVITED) {
    return "Customer already invited";
  } else if (formError.errorCode === ErrorCode.INVALID_TEMPLATE) {
    return "Invalid template";
  } else if (formError.errorCode === ErrorCode.EMPTY_TEMPLATE_NAME) {
    return "Empty template name";
  } else if (formError.errorCode === ErrorCode.EMPTY_TEMPLATE_CONTENT) {
    return "Empty template";
  } else if (formError.errorCode === ErrorCode.INVALID_EMAIL) {
    return "Ivalid email address";
  } else if (formError.errorCode === ErrorCode.PASSWORD_NOT_STRONG_ENOUGH) {
    return "Password has to be at least 5 characters long";
  } else if (formError.errorCode === ErrorCode.INVALID_TEMPLATE_PARAMETERS) {
    return "Invalid template parameters format";
  } else if (formError.errorCode === ErrorCode.REFRESH_TOKEN_EXPIRED) {
    return ""; // Hacky. Empty toast won't be shown
  }

  return (
    "Unexpected error. " +
    (formError.correlationId ? "MessageID: " + formError.correlationId : "")
  );
}
