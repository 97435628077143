import { useDispatch, useSelector } from "react-redux";

import {
  CustomerWorkspaceRole,
  ErrorResponse,
  WorkspaceCustomerDto,
} from "../../../api/types";
import { deleteWorkspaceMember } from "../../../api/workspace";
import { toastRequested } from "../../../store/actions";
import { IRootState } from "../../../store/store";
import { getAccessToken, isAdmin } from "../../../utils/AuthUtils";
import { getErrorMessage } from "../../../utils/ErrorUtils";
import { Format, formatEpochSeconds } from "../../../utils/TimeUtils";
import Badge from "../layout/utils/Badge";
import Button from "../layout/utils/Button";
import Card, { CardAction } from "../layout/utils/Card";
import Icon from "../layout/utils/Icon";
import Tooltip from "../layout/utils/Tooltip";

export function MembersTable({
  members,
  activeWorkspaceId,
  reloadMembers,
  handleNewMemberOpen,
  handleEditPermissionsOpen,
}: {
  members: WorkspaceCustomerDto[];
  activeWorkspaceId: string;
  reloadMembers: () => void;
  handleNewMemberOpen: () => void;
  handleEditPermissionsOpen: (customerId: WorkspaceCustomerDto) => void;
}) {
  const dispatch = useDispatch();
  const activeCustomerWorkspace = useSelector(
    (state: IRootState) => state.activeWorkspace
  );

  function handleDeleteMember(customerId: string): void {
    let accessToken = getAccessToken();
    if (!accessToken) return;

    deleteWorkspaceMember(accessToken, activeWorkspaceId, customerId)
      .then(() => {
        reloadMembers();
        dispatch(
          toastRequested({
            type: "success",
            message: "Workspace member removed",
          })
        );
      })
      .catch((errorResponse: ErrorResponse) => {
        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      });
  }

  function getRoleBadge(
    role: CustomerWorkspaceRole,
    member: WorkspaceCustomerDto
  ) {
    let bgColor: "green" | "blue" =
      role == CustomerWorkspaceRole.ADMIN ? "green" : "blue";

    return (
      <Badge key={member.customer.id + role} color={bgColor}>
        {role}
      </Badge>
    );
  }

  function getCardActions(): CardAction[] {
    let cardActions = [];

    if (activeCustomerWorkspace && isAdmin(activeCustomerWorkspace)) {
      cardActions.push({
        text: "New member",
        iconClass: "bi-plus-lg",
        handler: handleNewMemberOpen,
      });
    }

    return cardActions;
  }

  return (
    <Card title='Members' cardActions={getCardActions()}>
      <div className='p-3'>
        {/* Table */}
        <div className='overflow-x-auto'>
          <table className='table-auto w-full'>
            {/* Table header */}
            <thead className='text-xs uppercase text-slate-400 bg-slate-50 rounded-sm'>
              <tr>
                <th className='p-2'>
                  <div className='font-semibold text-left'>
                    Email
                    <Icon className='bi-caret-down-fill ml-1' size='base' />
                  </div>
                </th>
                <th className='p-2'>
                  <div className='font-semibold text-left'>Roles</div>
                </th>
                <th className='p-2'>
                  <div className='font-semibold text-left'>Joined</div>
                </th>
                <th className='p-2'></th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className='text-sm font-medium divide-y divide-slate-100'>
              {/* Row */}
              {members.map((member: WorkspaceCustomerDto) => (
                <tr key={member.customer.id}>
                  <td className='p-2 min-h-[3rem] inline-block'>
                    {member.customer.username}
                  </td>
                  <td className='p-2'>
                    {member.roles.map((role: CustomerWorkspaceRole) =>
                      getRoleBadge(role, member)
                    )}
                  </td>
                  <td className='p-2'>
                    {formatEpochSeconds(member.createdAt, Format.SHORT)}
                  </td>

                  <td className='p-2'>
                    <div className='text-right min-w-[5rem]'>
                      {isAdmin(activeCustomerWorkspace) ? (
                        <>
                          <Tooltip text='Change permission'>
                            <Button
                              iconClass='bi-pencil'
                              size='small'
                              iconSize='lg'
                              color='primary'
                              onClick={() => handleEditPermissionsOpen(member)}
                            />
                          </Tooltip>
                          <Tooltip text='Remove'>
                            <Button
                              iconClass='bi-x-lg'
                              size='small'
                              iconSize='lg'
                              color='alert'
                              onClick={() =>
                                handleDeleteMember(member.customer.id)
                              }
                              className='ml-1'
                            />
                          </Tooltip>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
}
