import { createAction } from "@reduxjs/toolkit";

import {
  BillingPeriod,
  CustomerWorkspacesResponse,
  ErrorResponse,
  ProductCode,
} from "../api/types";
import {
  createWorkspace,
  deleteWorkspace,
  getWorkspaces,
  updateWorkspace,
} from "../api/workspace";
import { ToastType } from "../src/components/layout/utils/Toast";
import { ACTIVE_CUSTOMER_WORKSPACE } from "../utils/Constants";
import { getErrorMessage } from "../utils/ErrorUtils";

type ToastRequestedPayload = {
  message: string;
  type: ToastType;
};
const toastRequested = createAction(
  "toast/requested",
  (payload: ToastRequestedPayload) => ({
    payload,
  })
);

const toastClosed = createAction("toast/closed");

const workspacesFetched = createAction("workspace/fetched", (payload) => ({
  payload,
}));
const workspaceCreated = createAction("workspace/created", (payload) => ({
  payload,
}));
const workspaceDeleted = createAction("workspace/deleted", (payload) => ({
  payload,
}));

const activeWorkspaceChanged = createAction(
  "activeWorkspace/changed",
  (payload) => ({ payload })
);

function workspacesRequested(accessToken: string): any {
  return (dispatch: any) => {
    getWorkspaces(accessToken)
      .then((customerWorkspacesResponse: CustomerWorkspacesResponse) => {
        dispatch(workspacesFetched(customerWorkspacesResponse.workspaces));
      })
      .catch((errorResponse: ErrorResponse) => {
        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      });
  };
}

function workspaceCreationRequested(
  accessToken: string,
  productCode: ProductCode,
  billingPeriod: BillingPeriod,
  name: string
): any {
  return (dispatch: any) => {
    createWorkspace(accessToken, productCode, billingPeriod, name)
      .then((customerWorkspace) => {
        dispatch(workspaceCreated(customerWorkspace));
        dispatch(workspacesRequested(accessToken));
        dispatch(
          toastRequested({ type: "success", message: "Workspace created" })
        );
      })
      .catch((errorResponse: ErrorResponse) => {
        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      });
  };
}

function activeWorkspaceRequested(): any {
  return (displatch: any) => {
    const activeCustomerWorkspace = window.localStorage.getItem(
      ACTIVE_CUSTOMER_WORKSPACE
    );

    if (!activeCustomerWorkspace) return;

    displatch(activeWorkspaceChanged(JSON.parse(activeCustomerWorkspace)));
  };
}

function workspaceDeletionRequested(
  accessToken: string,
  workspaceId: string
): any {
  return (dispatch: any) => {
    deleteWorkspace(accessToken, workspaceId)
      .then(() => {
        dispatch(workspaceDeleted(workspaceId));
        dispatch(workspacesRequested(accessToken));
        dispatch(
          toastRequested({
            type: "success",
            message: "Workspace deleted",
          })
        );
      })
      .catch((errorResponse: ErrorResponse) => {
        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      });
  };
}

function workspaceUpdateRequested(
  accessToken: string,
  workspaceId: string,
  name: string
): any {
  return (dispatch: any) => {
    updateWorkspace(accessToken, workspaceId, name)
      .then(() => {
        dispatch(workspacesRequested(accessToken));
        dispatch(
          toastRequested({
            type: "success",
            message: "Workspace name changed",
          })
        );
      })
      .catch((errorResponse: ErrorResponse) => {
        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      });
  };
}

export {
  activeWorkspaceChanged,
  activeWorkspaceRequested,
  toastClosed,
  toastRequested,
  workspaceCreated,
  workspaceCreationRequested,
  workspaceDeleted,
  workspaceDeletionRequested,
  workspacesFetched,
  workspacesRequested,
  workspaceUpdateRequested,
};
