import { useState } from "react";
import { useDispatch } from "react-redux";

import { ErrorResponse, TemplateDetailsLiteDto } from "../../../api/types";
import { deleteTemplate } from "../../../api/workspace";
import { toastRequested } from "../../../store/actions";
import { getAccessToken } from "../../../utils/AuthUtils";
import { getErrorMessage } from "../../../utils/ErrorUtils";
import { Format, formatEpochSeconds } from "../../../utils/TimeUtils";
import Button from "../layout/utils/Button";
import Card from "../layout/utils/Card";
import Icon from "../layout/utils/Icon";
import Modal from "../layout/utils/Modal";
import Tooltip from "../layout/utils/Tooltip";

export function TemplatesTable({
  templates,
  activeWorkspaceId,
  onTemplateReload,
  onNewTemplateClick,
  onEditTemplateClick,
}: {
  templates: TemplateDetailsLiteDto[];
  activeWorkspaceId: string;
  onTemplateReload: () => void;
  onNewTemplateClick: () => void;
  onEditTemplateClick: (templateId: string, templateName: string) => void;
}) {
  const dispatch = useDispatch();
  const [templateIdToDelete, setTemplateIdToDelete] = useState("");

  function handleDeleteTemplate(): void {
    let accessToken = getAccessToken();

    if (!accessToken || !templateIdToDelete) return;

    deleteTemplate(accessToken, activeWorkspaceId, templateIdToDelete)
      .then(() => {
        onTemplateReload();
        dispatch(
          toastRequested({ type: "success", message: "Teplate deleted" })
        );
        setTemplateIdToDelete("");
      })
      .catch((errorResponse: ErrorResponse) => {
        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      });
  }

  return (
    <>
      <Card
        title='Templates'
        cardActions={[
          {
            text: "New template",
            iconClass: "bi-plus-lg",
            handler: onNewTemplateClick,
          },
        ]}
      >
        <div className='p-3'>
          {/* Table */}
          <div className='overflow-x-auto'>
            <table className='table-auto w-full'>
              {/* Table header */}
              <thead className='text-xs uppercase text-slate-400 bg-slate-50 rounded-sm'>
                <tr>
                  <th className='p-2'>
                    <div className='font-semibold text-left'>
                      Name
                      <Icon className='bi-caret-down-fill ml-1' size='base' />
                    </div>
                  </th>
                  <th className='p-2'>
                    <div className='font-semibold text-left'>Template ID</div>
                  </th>
                  <th className='p-2'>
                    <div className='font-semibold text-left'>Created</div>
                  </th>
                  <th className='p-2'>
                    <div className='font-semibold text-left'>Last Updated</div>
                  </th>
                  <th className='p-2'>
                    <div className='font-semibold text-right'></div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody className='text-sm font-medium divide-y divide-slate-100'>
                {/* Row */}
                {templates.map((templateDetailsDto: TemplateDetailsLiteDto) => (
                  <tr key={templateDetailsDto.id}>
                    <td className='p-2'>{templateDetailsDto.name}</td>
                    <td className='p-2'>
                      <div className='text-left'>{templateDetailsDto.id}</div>
                    </td>
                    <td className='p-2'>
                      <div className='text-left'>
                        {formatEpochSeconds(
                          templateDetailsDto.createdAt,
                          Format.SHORT
                        )}
                      </div>
                    </td>
                    <td className='p-2'>
                      <div className='text-left'>
                        {formatEpochSeconds(
                          templateDetailsDto.updatedAt,
                          Format.SHORT
                        )}
                      </div>
                    </td>

                    <td className='p-2'>
                      <div className='text-right min-w-[7rem]'>
                        <Tooltip text='Edit'>
                          <Button
                            iconClass='bi-pencil'
                            size='small'
                            iconSize='lg'
                            color='primary'
                            onClick={() =>
                              onEditTemplateClick(
                                templateDetailsDto.id,
                                templateDetailsDto.name
                              )
                            }
                            className='ml-1'
                          />
                        </Tooltip>
                        <Tooltip text='Delete'>
                          <Button
                            iconClass='bi-trash'
                            size='small'
                            iconSize='lg'
                            color='alert'
                            onClick={() =>
                              setTimeout(() =>
                                setTemplateIdToDelete(templateDetailsDto.id)
                              )
                            }
                            className='ml-1'
                          />
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Card>

      <Modal
        id='table-delete-template-modal'
        title='Delete template'
        modalOpen={!!templateIdToDelete}
        setModalClosed={() => setTemplateIdToDelete("")}
      >
        <div className='w-full mt-5'>
          <p className='mb-3 mr-5'>
            Are you sure? This action is not reversible
          </p>
          <Button
            type='button'
            color='alert'
            text='Delete'
            size='large'
            width='max'
            onClick={handleDeleteTemplate}
          />
        </div>
      </Modal>
    </>
  );
}
