import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { ErrorCode } from "../../../api/types";
import { toastRequested } from "../../../store/actions";
import { storeAuthData } from "../../../utils/AuthUtils";
import { getErrorMessage } from "../../../utils/ErrorUtils";

function OAuth2Redirect() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const accessToken = searchParams.get("access_token");
  const refreshToken = searchParams.get("refresh_token");
  const roles = searchParams.getAll("roles");
  const errorString = searchParams.get("error");
  const error = errorString ? JSON.parse(errorString) : null;
  const inviteId = searchParams.get("invite_id");
  const redirect = searchParams.get("redirect");

  useEffect(() => {
    if (accessToken && refreshToken && roles) {
      storeAuthData(accessToken, refreshToken, roles);

      if (inviteId) {
        navigate({
          pathname: "/join-workspace",
          search: `?${createSearchParams({
            invite: inviteId,
          })}`,
        });
      } else if (redirect) {
        navigate(redirect);
      } else {
        navigate("/");
      }
    } else if (error && error.errorCode === ErrorCode.USERNAME_ALREADY_IN_USE) {
      dispatch(
        toastRequested({
          type: "error",
          message: "Authentication failed. Try different method",
        })
      );
      navigate("/");
    } else if (error) {
      dispatch(
        toastRequested({
          type: "error",
          message: getErrorMessage(error),
        })
      );
      navigate("/");
    }
  }, [dispatch, navigate, accessToken, refreshToken, roles, inviteId, error]);

  return <></>;
}

export default OAuth2Redirect;
