import "prism-code-editor/grammars/json";
import "prism-code-editor/grammars/markup";
import "prism-code-editor/languages";

import { PrismEditor } from "prism-code-editor";
import {
  fullEditor,
  SetupOptions,
  updateTheme,
} from "prism-code-editor/setups";
import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  ErrorCode,
  ErrorResponse,
  TemplateDetailsDto,
} from "../../../api/types";
import {
  addTemplate,
  deleteTemplate,
  generatePdf,
  getTemplate,
  updateTemplate,
} from "../../../api/workspace";
import { toastRequested } from "../../../store/actions";
import { IRootState } from "../../../store/store";
import { getAccessToken } from "../../../utils/AuthUtils";
import { getErrorMessage } from "../../../utils/ErrorUtils";
import { isValidJsonString } from "../../../utils/StringUtils";
import Button from "../../components/layout/utils/Button";
import Card, { CardAction } from "../../components/layout/utils/Card";
import Modal from "../../components/layout/utils/Modal";
import { PageLoader } from "../../components/layout/utils/PageLoader";

export const PrismEditorReact = forwardRef(
  (props: SetupOptions, ref: ForwardedRef<PrismEditor>) => {
    const divRef = useRef<HTMLDivElement>(null);
    const editorRef = useRef<PrismEditor>();

    useEffect(() => {
      editorRef.current?.setOptions(props);
    }, [props]);

    useEffect(() => {
      editorRef.current && updateTheme(editorRef.current, props.theme);
    }, [props.theme]);

    useEffect(() => {
      const editor = (editorRef.current = fullEditor(divRef.current!, props));
      if (ref) typeof ref == "function" ? ref(editor) : (ref.current = editor);
      return editor.remove;
    }, []);

    return <div className='prism-editor-react' ref={divRef} />;
  }
);

export default function CreateEditTemplate() {
  const dispatch = useDispatch();
  const accessToken = getAccessToken();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const templateId = searchParams.get("templateId");

  const [templateName, setTemplateName] = useState<string | null>(null);
  const [templateContent, setTemplateContent] = useState<string | null>(null);
  const [templateParameters, setTemplateParameters] = useState<string | null>(
    null
  );
  const [pdfBlob, setPdfBlob] = useState<string | null>(null);
  const [formErrorCodes, setFormErrorCodes] = useState<ErrorCode[]>([]);
  const [isCreateEditLoading, setIsCreateEditLoading] = useState(false);
  const [isGenerateLoading, setIsGenerateLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isViewLoading, setIsViewLoading] = useState(isEditFlow());
  const [deleteTemplateModalOpen, setDeleteTemplateModalOpen] = useState(false);

  const activeCustomerWorkspace = useSelector(
    (state: IRootState) => state.activeWorkspace
  );

  useEffect(() => {
    if (templateId && isEditFlow()) {
      if (!accessToken || !activeCustomerWorkspace) return;

      if (!templateName || !templateContent) {
        setIsViewLoading(true);
      }

      getTemplate(accessToken, activeCustomerWorkspace.workspace.id, templateId)
        .then((templateDetailsDto: TemplateDetailsDto) => {
          setTemplateName(templateDetailsDto.name);
          setTemplateContent(atob(templateDetailsDto.templateBase64));
        })
        .catch((errorResponse: ErrorResponse) => {
          navigate("/templates");

          dispatch(
            toastRequested({
              type: "error",
              message: getErrorMessage(errorResponse),
            })
          );
        })
        .finally(() => {
          setIsViewLoading(false);
        });
    }
  }, [templateId]);

  function getCreateEditTitle(): string {
    if (isCreateFlow()) {
      return "Create new template.";
    } else if (isEditFlow()) {
      return "Edit template.";
    } else {
      // TODO ERROR
      return "";
    }
  }

  function getCreateEditButtonText(): string {
    if (isCreateFlow()) {
      return "Create";
    } else if (isEditFlow()) {
      return "Save";
    } else {
      // TODO ERROR
      return "";
    }
  }

  function isCreateFlow(): boolean {
    return !templateId;
  }

  function isEditFlow(): boolean {
    return !!templateId || false;
  }

  function isResultModalOpen(): boolean {
    return pdfBlob != null;
  }

  function handleCreateEdit() {
    if (isCreateFlow()) {
      handleAddTemplate();
    } else if (isEditFlow()) {
      handleEditTemplate();
    } else {
      // TODO ERROR
    }
  }

  function handleAddTemplate(): void {
    setFormErrorCodes([]);
    try {
      validateTemplateName();
      validateTemplateContent();
    } catch {
      return;
    }

    if (
      !accessToken ||
      !activeCustomerWorkspace ||
      !templateName ||
      !templateContent
    )
      return;

    setIsCreateEditLoading(true);

    addTemplate(
      accessToken,
      activeCustomerWorkspace.workspace.id,
      templateName,
      btoa(templateContent)
    )
      .then((templateDetailsDto: TemplateDetailsDto) => {
        dispatch(
          toastRequested({ type: "success", message: "Template created" })
        );
        navigate("/templates/create-edit?templateId=" + templateDetailsDto.id);
      })
      .catch((errorResponse: ErrorResponse) => {
        setFormErrorCodes([errorResponse.errorCode]);

        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      })
      .finally(() => {
        setIsCreateEditLoading(false);
      });
  }

  function handleEditTemplate(): void {
    setFormErrorCodes([]);
    try {
      validateTemplateName();
      validateTemplateContent();
    } catch {
      return;
    }

    if (
      !accessToken ||
      !activeCustomerWorkspace ||
      !templateId ||
      !templateName ||
      !templateContent
    )
      return;

    setIsCreateEditLoading(true);

    updateTemplate(
      accessToken,
      activeCustomerWorkspace.workspace.id,
      templateId,
      templateName,
      btoa(templateContent)
    )
      .catch((errorResponse: ErrorResponse) => {
        setFormErrorCodes([errorResponse.errorCode]);

        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      })
      .finally(() => {
        setIsCreateEditLoading(false);
      });
  }

  function handleDeleteTemplate(): void {
    if (
      !accessToken ||
      !activeCustomerWorkspace ||
      !templateId ||
      !isEditFlow()
    )
      return;

    setIsDeleteLoading(true);

    deleteTemplate(
      accessToken,
      activeCustomerWorkspace.workspace.id,
      templateId
    )
      .then(() => {
        navigate("/templates");

        dispatch(
          toastRequested({ type: "success", message: "Teplate deleted" })
        );
      })
      .catch((errorResponse: ErrorResponse) => {
        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      })
      .finally(() => {
        setIsDeleteLoading(false);
      });
  }

  function handleGeneratePdf(): void {
    setFormErrorCodes([]);
    try {
      validateTemplateContent();
      validateTemplateParameters();
    } catch {
      return;
    }

    if (!accessToken) return;

    setIsGenerateLoading(true);

    generatePdf(
      accessToken,
      btoa(templateContent || ""),
      templateParameters ? JSON.parse(templateParameters) : {}
    )
      .then((blob: Blob) => {
        setPdfBlob(URL.createObjectURL(blob));
      })
      .catch((errorResponse: ErrorResponse) => {
        setFormErrorCodes([errorResponse.errorCode]);

        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      })
      .finally(() => {
        setIsGenerateLoading(false);
      });
  }

  function validateTemplateName(): void {
    if (!templateName) {
      setFormErrorCodes((errors) => [...errors, ErrorCode.EMPTY_TEMPLATE_NAME]);

      dispatch(
        toastRequested({
          type: "error",
          message: getErrorMessage({
            errorCode: ErrorCode.EMPTY_TEMPLATE_NAME,
            correlationId: "",
          }),
        })
      );

      throw new Error();
    }
  }

  function validateTemplateContent(): void {
    if (!templateContent) {
      let newErrorCode = ErrorCode.EMPTY_TEMPLATE_CONTENT;

      setFormErrorCodes((errors) => [...errors, newErrorCode]);

      dispatch(
        toastRequested({
          type: "error",
          message: getErrorMessage({
            errorCode: newErrorCode,
            correlationId: "",
          }),
        })
      );

      throw new Error();
    }
  }

  function validateTemplateParameters(): void {
    if (templateParameters && !isValidJsonString(templateParameters)) {
      let newErrorCode = ErrorCode.INVALID_TEMPLATE_PARAMETERS;

      setFormErrorCodes((errors) => [...errors, newErrorCode]);

      dispatch(
        toastRequested({
          type: "error",
          message: getErrorMessage({
            errorCode: newErrorCode,
            correlationId: "",
          }),
        })
      );

      throw new Error();
    }
  }

  function isNameError(): boolean {
    return (
      formErrorCodes?.includes(ErrorCode.TEMPLATE_NAME_ALREADY_IN_USE) ||
      formErrorCodes?.includes(ErrorCode.EMPTY_TEMPLATE_NAME)
    );
  }

  function isContentError(): boolean {
    return (
      formErrorCodes?.includes(ErrorCode.EMPTY_TEMPLATE_CONTENT) ||
      formErrorCodes?.includes(ErrorCode.INVALID_TEMPLATE)
    );
  }

  function isTemplateParametersError(): boolean {
    return formErrorCodes?.includes(ErrorCode.INVALID_TEMPLATE_PARAMETERS);
  }

  function getCardActions(): CardAction[] {
    let cardActions: CardAction[] = [];

    if (isEditFlow()) {
      cardActions.push({
        text: "Delete",
        buttonColor: "alert",
        className: "mr-2",
        handler: () => setTimeout(() => setDeleteTemplateModalOpen(true)),
      });
    }

    cardActions.push({
      text: getCreateEditButtonText(),
      buttonColor: "primary",
      loading: isCreateEditLoading,
      handler: handleCreateEdit,
    });

    return cardActions;
  }

  if (isViewLoading || isDeleteLoading) return <PageLoader />;

  return (
    <>
      <div className='max-w-[100rem] mx-auto flex flex-row flex-wrap gap-4 items-start'>
        <Card
          cardActions={getCardActions()}
          title={getCreateEditTitle()}
          className='flex-1 min-w-[60%] lg:min-w-0'
        >
          <label
            className='block text-gray-800 text-sm font-medium mb-1 mt-5'
            htmlFor='email'
          >
            Template name <span className='text-red-600'>*</span>
          </label>
          <input
            id='template-name'
            className={`form-input w-full text-gray-800 ${
              isNameError() ? "!border-rose-500" : ""
            }`}
            placeholder='Name here'
            disabled={isEditFlow() && templateName == null}
            value={templateName || ""}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setTemplateName(event.target.value)
            }
          />
          <label
            className='block text-gray-800 text-sm font-medium mb-1 mt-5'
            htmlFor='email'
          >
            Template (HTML) <span className='text-red-600'>*</span>
          </label>
          <div
            className={`form-input !py-0.5 !px-0 overflow-y-scroll ${
              isContentError() ? "!border-rose-500" : ""
            }`}
          >
            <PrismEditorReact
              theme='github-light'
              language='html'
              readOnly={isEditFlow() && templateContent == null}
              value={templateContent || ""}
              onUpdate={setTemplateContent}
            />
          </div>
        </Card>
        <Card title='Give it a try.' className='flex-1 min-w-[60%] lg:min-w-0'>
          <label
            className='block text-sm font-medium mb-1 mt-5'
            htmlFor='email'
          >
            Template parameters (JSON)
          </label>
          <div>
            <div
              className={`form-input !py-0.5 !px-0 ${
                isTemplateParametersError() ? "!border-rose-500" : ""
              }`}
            >
              <PrismEditorReact
                theme='github-light'
                language='json'
                value={templateParameters || ""}
                onUpdate={setTemplateParameters}
              />
            </div>
          </div>
          <div className='w-full mt-5'>
            <Button
              type='button'
              color='primary'
              text='Generate PDF'
              size='large'
              width='max'
              loading={isGenerateLoading}
              onClick={handleGeneratePdf}
            />
          </div>
        </Card>
      </div>

      <Modal
        id='generate-result-modal'
        title='Result'
        modalOpen={isResultModalOpen()}
        setModalClosed={() => setPdfBlob(null)}
        className='overflow-auto'
      >
        <div className='bg-red-500'>
          <div className='max-w-[75rem] w-[90vw] h-[75vh]'>
            <iframe src={pdfBlob || ""} height='100%' width='100%'></iframe>
          </div>
        </div>
      </Modal>

      <Modal
        id='delete-template-modal'
        title='Delete template'
        modalOpen={deleteTemplateModalOpen}
        setModalClosed={() => setDeleteTemplateModalOpen(false)}
      >
        <form onSubmit={handleDeleteTemplate}>
          <div className='w-full mt-5'>
            <p className='mb-3 mr-5'>
              Are you sure? This action is not reversible
            </p>
            <Button
              type='submit'
              color='alert'
              text='Delete'
              size='large'
              width='max'
            />
          </div>
        </form>
      </Modal>
    </>
  );
}
