import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { resendEmailVerification } from "../../../api/auth";
import { toastRequested } from "../../../store/actions";
import Button from "../../components/layout/utils/Button";

function VerifyEmailInfo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  let email = searchParams.get("email");

  useEffect(() => {
    if (!email) {
      navigate("/");
    }
  }, [navigate, email]);

  function resendVerification(): void {
    if (email) {
      resendEmailVerification(email)
        .then(() => {
          dispatch(toastRequested({ type: "success", message: "Email sent" }));
        })
        .catch(() => {
          dispatch(
            toastRequested({ type: "error", message: "Failed to send email" })
          );
        });
    }
  }

  if (!email) return <></>; // TODO Something went wrong

  return (
    <section className='font-inter antialiased tracking-tight bg-gradient-to-b from-gray-100 to-white'>
      <div className='max-w-6xl mx-auto px-4 sm:px-6'>
        <div className='pt-32 pb-12 md:pt-60 md:pb-20'>
          {/* Page header */}
          <div className='max-w-3xl mx-auto text-center pb-12 md:pb-20'>
            <h1 className='h3'>Verify your email address</h1>
            <p className='text-xl text-gray-600 mt-1'>
              We have sent an email to{" "}
              <span className='font-bold'>{email}</span> with a link to activate
              your account.
            </p>
            <p className='text-xl text-gray-600 mt-14'>
              Didn&apos;t get an email? Check your{" "}
              <span className='font-bold'>spam folder!</span>
            </p>
            <Button
              text='Resend email'
              size='large'
              color='secondary'
              onClick={resendVerification}
              className='mt-4'
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default VerifyEmailInfo;
