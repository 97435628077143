import { API_URL } from "../api/common";

export type OAuth2Provider = "google" | "github";

export type OAuthLinkProps = {
  authProvider: OAuth2Provider;
  inviteId?: string;
  redirect?: string;
};

export function getOAuthLink({
  authProvider,
  inviteId,
  redirect,
}: OAuthLinkProps): string {
  let REDIRECT_URI = `${window.location.origin}/oauth2/redirect`;

  if (inviteId) {
    REDIRECT_URI += `?invite_id=${inviteId}`;
  }

  if (redirect) {
    REDIRECT_URI += `?redirect=${redirect}`;
  }

  let url = `${API_URL}/oauth2/authorize/${authProvider}?redirect_uri=${REDIRECT_URI}`;

  return url;
}
