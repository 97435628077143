import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  CustomerWorkspaceDto,
  ErrorCode,
  ErrorResponse,
} from "../../../api/types";
import { createWorkspace } from "../../../api/workspace";
import {
  toastRequested,
  workspaceCreated,
  workspacesRequested,
} from "../../../store/actions";
import { IRootState } from "../../../store/store";
import { getAccessToken } from "../../../utils/AuthUtils";
import { getErrorMessage } from "../../../utils/ErrorUtils";
import PricingPlans, { PlanDetails } from "../../components/app/PricingPlans";
import { PageLoader } from "../../components/layout/utils/PageLoader";
import { reportConversion } from "../../utils/GoogleTagUtils";

export default function CreateWorkspace() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newWorkspaceName, setNewWorkspaceName] = useState("");
  const workspaces = useSelector((state: IRootState) => state.workspaces);
  const [formErrorCode, setFormErrorCode] = useState<ErrorCode | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      workspaces &&
      workspaces.findIndex(
        (workspace: CustomerWorkspaceDto) =>
          workspace.workspace.name === newWorkspaceName
      ) >= 0
    ) {
      navigate("/");
    }
  }, [navigate, workspaces]);

  function handlePlanSelected(planDetails: PlanDetails) {
    const accessToken = getAccessToken();

    if (!accessToken) return;

    if (!newWorkspaceName) {
      setFormErrorCode(ErrorCode.EMPTY_WORKSPACE_NAME);
      dispatch(
        toastRequested({
          type: "error",
          message: "Workspace name cannot be emtpy",
        })
      );
      return;
    }

    reportConversion(planDetails.productCode);

    setIsLoading(true);

    createWorkspace(
      accessToken,
      planDetails.productCode,
      planDetails.billingPeriod,
      newWorkspaceName
    )
      .then((customerWorkspace) => {
        dispatch(workspaceCreated(customerWorkspace));
        dispatch(workspacesRequested(accessToken));
      })
      .catch((errorResponse: ErrorResponse) => {
        setIsLoading(false);

        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      });
  }

  function handlePlanFetchingFailed(errorResponse: ErrorResponse) {
    dispatch(
      toastRequested({ type: "error", message: getErrorMessage(errorResponse) })
    );
  }

  function isNameError(): boolean {
    return formErrorCode === ErrorCode.EMPTY_WORKSPACE_NAME;
  }

  if (isLoading) return <PageLoader />;

  return (
    <div className='font-inter antialiased tracking-tight bg-gradient-to-b from-gray-100 to-white py-20 px-20'>
      <h1 className='h2 w-full mr-2 text-center'>Create a new workspace.</h1>
      <p className='mt-1 text-gray-600 text-center'>
        Whatever your status, our offers evolve according to your needs.
      </p>

      <div className='max-w-md m-auto mt-12'>
        <label
          className={`block text-gray-800 text-sm font-medium mb-1 mt-5 `}
          htmlFor='email'
        >
          Workspace name <span className='text-red-600'>*</span>
        </label>
        <input
          id='workspace-name'
          className={`form-input w-full text-gray-800 ${
            isNameError() ? "!border-rose-500" : ""
          }`}
          placeholder='My workspace'
          value={newWorkspaceName}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setNewWorkspaceName(event.target.value)
          }
        />
      </div>

      <PricingPlans
        onPlanChanged={handlePlanSelected}
        onPlanFetchingFailed={handlePlanFetchingFailed}
        buttonText='Start free trial'
      />
    </div>
  );
}
