import axios from "axios";

import { API_URL } from "../api/common";
import {
  CustomerWorkspaceDto,
  CustomerWorkspaceRole,
  RefreshResponse,
} from "../api/types";

export function getAccessToken() {
  return getValue(StorageKey.AccessToken);
}

export function getRefreshToken() {
  return getValue(StorageKey.RefreshToken);
}

export function storeAuthData(
  accessToken: string,
  refreshToken: string,
  roles: string[]
) {
  storeValue(StorageKey.AccessToken, accessToken);
  storeValue(StorageKey.RefreshToken, refreshToken);
  storeValue(StorageKey.Roles, JSON.stringify(roles));
}

export async function updateAccessToken(accessToken: string) {
  storeValue(StorageKey.AccessToken, accessToken);
}

export function removeAuthData() {
  window.localStorage.removeItem(StorageKey.AccessToken);
  window.localStorage.removeItem(StorageKey.RefreshToken);
  window.localStorage.removeItem(StorageKey.Roles);
}

enum StorageKey {
  AccessToken = "accessToken",
  RefreshToken = "refreshToken",
  Roles = "roles",
}

function storeValue(storageKey: StorageKey, value: string) {
  window.localStorage.setItem(storageKey, value);
}

function getValue(storageKey: StorageKey) {
  return window.localStorage.getItem(storageKey);
}

export type JWTPayload = {
  sub: string;
  iat: number;
  exp: number;
};

export function parseJwt(token: string): JWTPayload {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload) as JWTPayload;
}

export function isAdmin(customerWorkspace: CustomerWorkspaceDto): boolean {
  return customerWorkspace?.roles[0] === CustomerWorkspaceRole.ADMIN;
}

export const axiosAuth = axios.create();

axiosAuth.interceptors.response.use(undefined, async (axiosError) => {
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();
  const config = axiosError.config;

  // console.log("axiosError", axiosError);

  if (
    axiosError.response?.status == 401 &&
    !config.__isRetryRequest &&
    accessToken &&
    refreshToken
  ) {
    const { sub } = parseJwt(accessToken);

    let refreshResponse;
    try {
      refreshResponse = await refreshAccessToken(sub, refreshToken);
    } catch (refreshAxiosError) {
      removeAuthData();
      location.reload();

      return Promise.reject(refreshAxiosError);
    }

    const newAccessToken = refreshResponse.accessToken;
    const newRefreshToken = refreshResponse.refreshToken;
    const newRoles = refreshResponse.roles;

    config.headers["Authorization"] = `Bearer ${newAccessToken}`;
    config.__isRetryRequest = true;

    storeAuthData(newAccessToken, newRefreshToken, newRoles);

    return axiosAuth(config);
  } else {
    return Promise.reject(axiosError);
  }
});

axiosAuth.interceptors.request.use((config) => {
  const accessToken = getAccessToken();

  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  config.headers["Content-Type"] = `application/json`;

  return config;
});

const refreshAccessToken: (
  username: string,
  refreshToken: string
) => Promise<RefreshResponse> = async (username, refreshToken) => {
  return axiosAuth({
    method: "POST",
    url: API_URL + "/api/app/auth/refreshtoken",
    data: JSON.stringify({ username, refreshToken }),
  }).then((response) => {
    return response.data as RefreshResponse;
  });
};
