import { makeRequestAndReturnJSON } from "./common";
import {
  CustomerDto,
  ErrorResponse,
  LoginResponse,
  MessageResponse,
} from "./types";

export const registerUser: (
  username: string,
  password: string,
  inviteId: string | null
) => Promise<MessageResponse> = async (username, password, inviteId) => {
  return makeRequestAndReturnJSON({
    method: "POST",
    url: "/api/app/auth/register",
    body: JSON.stringify({ username, password, inviteId }),
  });
};

export const loginUser: (
  username: string,
  password: string
) => Promise<LoginResponse> = async (username, password) => {
  return makeRequestAndReturnJSON({
    method: "POST",
    url: "/api/app/auth/login",
    body: JSON.stringify({ username, password }),
  });
};

export const verifyEmail: (
  email: string,
  token: string
) => Promise<MessageResponse> = async (email, token) => {
  return makeRequestAndReturnJSON({
    method: "POST",
    url: "/api/app/auth/verify-email",
    body: JSON.stringify({ email, token }),
  });
};

export const resendEmailVerification: (
  username: string
) => Promise<MessageResponse | ErrorResponse> = async (username) => {
  return makeRequestAndReturnJSON({
    method: "POST",
    url: "/api/app/auth/resend-email-verification",
    body: JSON.stringify({ username }),
  });
};

export const startPasswordRecoveryChallenge: (
  username: string
) => Promise<MessageResponse> = async (username) => {
  return makeRequestAndReturnJSON({
    method: "POST",
    url: "/api/app/auth/password-recovery/start",
    body: JSON.stringify({ username }),
  });
};

export const submitPasswordRecoveryChallenge: (
  username: string,
  token: string,
  password: string
) => Promise<MessageResponse> = async (username, token, password) => {
  return makeRequestAndReturnJSON({
    method: "POST",
    url: "/api/app/auth/password-recovery/submit",
    body: JSON.stringify({ username, token, password }),
  });
};

export const udateCustomer: (
  accessToken: string,
  password: string
) => Promise<CustomerDto> = async (accessToken, password) => {
  return makeRequestAndReturnJSON({
    method: "PATCH",
    url: "/api/app/auth/customer",
    body: JSON.stringify({ password }),
    accessToken,
  });
};

export const deleteCustomer: (
  accessToken: string
) => Promise<MessageResponse> = async (accessToken) => {
  return makeRequestAndReturnJSON({
    method: "DELETE",
    url: "/api/app/auth/customer",
    accessToken,
  });
};
