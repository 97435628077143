export type IconSize = "base" | "lg" | "2xl" | "3xl" | "4xl";

type IconProps = {
  className: string;
  size?: IconSize;
};

function Icon({ className, size = "base" }: IconProps) {
  return <i className={`${"text-" + (size || "base")} bi ${className}`}></i>;
}

export default Icon;
