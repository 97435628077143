import * as Sentry from "@sentry/react";

export function SentryInitialiser({ ssr }: { ssr?: boolean }) {
  if (import.meta.env.PROD) {
    let integrations = [];
    if (!ssr) {
      integrations = [
        new Sentry.BrowserTracing({
          tracePropagationTargets: [/^https:\/\/dynamopdf\.com/],
        }),
        new Sentry.Replay(),
      ];
    }

    Sentry.init({
      dsn: "https://1c3b08016334bbe0220bd420ba22688e@o4506350831992832.ingest.sentry.io/4506350833893376",
      integrations,
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }

  return <></>;
}
