import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  getAccessToken,
  parseJwt,
  removeAuthData,
} from "../../../../utils/AuthUtils";
import Icon from "./Icon";
import Transition from "./Transition";

const DropdownProfile = () => {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef<HTMLButtonElement>(null);
  const dropdown = useRef<HTMLDivElement>(null);

  const accessToken = getAccessToken();

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        (target instanceof HTMLElement && dropdown.current.contains(target)) ||
        (target instanceof HTMLElement && trigger.current?.contains(target))
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ key }: KeyboardEvent) => {
      if (!dropdownOpen || key !== "Escape") return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  function handleGoToSettings() {
    setDropdownOpen(!dropdownOpen);
    navigate("/account");
  }

  function handleLogout() {
    removeAuthData();
    navigate("/");
  }

  if (!accessToken) return <div></div>;

  return (
    <div className='relative inline-flex'>
      <button
        ref={trigger}
        className='inline-flex justify-center items-center group'
        aria-haspopup='true'
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <Icon className='bi-person-circle' size='2xl' />
        <div className='flex items-center truncate'>
          <span className='truncate ml-2 text-sm font-medium text-slate-600 group-hover:text-slate-800'>
            {parseJwt(accessToken).sub}
          </span>
          <svg
            className='w-3 h-3 shrink-0 ml-2 fill-current text-slate-400'
            viewBox='0 0 12 12'
          >
            <path d='M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z' />
          </svg>
        </div>
      </button>

      <Transition
        className='origin-top-right z-10 absolute top-full min-w-44 bg-white border border-slate-200 py-2 rounded shadow-lg overflow-hidden mt-1 right-0'
        show={dropdownOpen}
        enter='transition ease-out duration-200 transform'
        enterStart='opacity-0 -translate-y-2'
        enterEnd='opacity-100 translate-y-0'
        leave='transition ease-out duration-200'
        leaveStart='opacity-100'
        leaveEnd='opacity-0'
        appear={false}
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <ul>
            <li>
              <a
                className='font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3 cursor-pointer'
                onClick={handleGoToSettings}
              >
                Settings
              </a>
            </li>
            <li>
              <a
                className='font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3 cursor-pointer'
                onClick={handleLogout}
              >
                Sign Out
              </a>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  );
};

export default DropdownProfile;
