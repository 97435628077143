import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ApiKeyDto,
  ApiKeysResponse,
  ErrorCode,
  ErrorResponse,
} from "../../../api/types";
import { addApiKey, getApiKeys } from "../../../api/workspace";
import { toastRequested } from "../../../store/actions";
import { IRootState } from "../../../store/store";
import { getAccessToken } from "../../../utils/AuthUtils";
import { getErrorMessage } from "../../../utils/ErrorUtils";
import { ApiKeysTable } from "../../components/app/ApiKeysTable";
import Button from "../../components/layout/utils/Button";
import Modal from "../../components/layout/utils/Modal";
import { PageLoader } from "../../components/layout/utils/PageLoader";

export default function ApiKeys() {
  const dispatch = useDispatch();
  const accessToken = getAccessToken();
  const [apiKeys, setApiKeys] = useState<ApiKeyDto[] | null>(null);
  const [newApiKeyName, setNewApiKeyName] = useState("");
  const [createApiKeyModalOpen, setCreateApiKeyModalOpen] = useState(false);
  const [formErrorCode, setFormErrorCode] = useState<ErrorCode | null>(null);

  const activeCustomerWorkspace = useSelector(
    (state: IRootState) => state.activeWorkspace
  );

  const loadApiKeys = useCallback(
    (accessToken: string, workspaceId: string) => {
      getApiKeys(accessToken, workspaceId)
        .then((apiKeysResponse: ApiKeysResponse) => {
          setApiKeys(apiKeysResponse.apiKeys);
        })
        .catch((errorResponse: ErrorResponse) => {
          dispatch(
            toastRequested({
              type: "error",
              message: getErrorMessage(errorResponse),
            })
          );
        });
    },
    [dispatch]
  );

  useEffect(() => {
    if (accessToken && activeCustomerWorkspace) {
      loadApiKeys(accessToken, activeCustomerWorkspace.workspace.id);
    }

    return () => {
      setApiKeys(null);
    };
  }, [loadApiKeys, activeCustomerWorkspace?.workspace.id]);

  useEffect(() => {
    if (!createApiKeyModalOpen) {
      setNewApiKeyName("");
      setFormErrorCode(null);
    }
  }, [createApiKeyModalOpen]);

  function handleCreateApiKey(event: FormEvent) {
    event.preventDefault();

    if (!activeCustomerWorkspace) return;

    if (!newApiKeyName) {
      setFormErrorCode(ErrorCode.EMPTY_API_KEY_NAME);
      dispatch(
        toastRequested({
          type: "error",
          message: "Name cannot be empty",
        })
      );
      return;
    }

    if (!accessToken) return;

    addApiKey(accessToken, activeCustomerWorkspace.workspace.id, newApiKeyName)
      .then(() => {
        setCreateApiKeyModalOpen(false);
        loadApiKeys(accessToken, activeCustomerWorkspace.workspace.id);
      })
      .catch((errorResponse: ErrorResponse) => {
        setFormErrorCode(errorResponse.errorCode);

        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      });
  }

  function isNameError() {
    return (
      formErrorCode === ErrorCode.API_KEY_NAME_ALREADY_IN_USE ||
      formErrorCode === ErrorCode.EMPTY_API_KEY_NAME
    );
  }

  if (!activeCustomerWorkspace || !apiKeys || !accessToken) {
    return <PageLoader />;
  }

  return (
    <>
      <ApiKeysTable
        apiKeys={apiKeys}
        activeWorkspaceId={activeCustomerWorkspace.workspace.id}
        reloadApiKeys={() =>
          loadApiKeys(accessToken, activeCustomerWorkspace.workspace.id)
        }
        handleNewApiKeyOpen={() =>
          setTimeout(() => setCreateApiKeyModalOpen(true))
        }
      />

      <Modal
        id='create-api-key-modal'
        title='Create new API key.'
        modalOpen={createApiKeyModalOpen}
        setModalClosed={() => setCreateApiKeyModalOpen(false)}
      >
        <form onSubmit={handleCreateApiKey}>
          <label
            className='block text-gray-800 text-sm font-medium mb-1 mt-5'
            htmlFor='email'
          >
            Name <span className='text-red-600'>*</span>
          </label>
          <input
            id='api-key-name'
            className={`form-input w-full text-gray-800 ${
              isNameError() ? "!border-rose-500" : ""
            }`}
            placeholder='Name here'
            value={newApiKeyName}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setNewApiKeyName(event.target.value)
            }
          />
          <div className='w-full mt-5'>
            <Button
              type='submit'
              color='primary'
              text='Create'
              size='large'
              width='max'
            />
          </div>
        </form>
      </Modal>
    </>
  );
}
