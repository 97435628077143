import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { getAccessToken } from "../../utils/AuthUtils";
import { ApplicationLayout } from "../components/layout/ApplicationLayout";

type AuthContainerProps = {
  wrappedToApplicationLayout?: boolean;
};

export const AuthContainer = ({
  wrappedToApplicationLayout,
}: AuthContainerProps) => {
  const navigate = useNavigate();

  const accessToken = getAccessToken();

  useEffect(() => {
    if (!accessToken) {
      navigate("/login");
    }
  }, [accessToken]);

  if (!accessToken) return <></>;

  if (!wrappedToApplicationLayout) return <Outlet />;

  return (
    <ApplicationLayout>
      <Outlet />
    </ApplicationLayout>
  );
};
