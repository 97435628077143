import { ProductCode } from "../../api/types";

export function reportConversion(productCode: ProductCode) {
  let subscriptionId;
  switch (productCode) {
    case ProductCode.FREE:
      subscriptionId = "U6BdCOT50f4YEPSymq4C";
      break;
    case ProductCode.STARTER:
      subscriptionId = "oQ4nCNrTzf4YEPSymq4C";
      break;
    case ProductCode.STANDARD:
      subscriptionId = "wpGjCJqbz_4YEPSymq4C";
      break;
    case ProductCode.PRO:
      subscriptionId = "4R9cCJPY0_4YEPSymq4C";
      break;
    default:
      throw new Error("Failed to track conversions. Unsupported product code");
  }

  gtag("event", "conversion", {
    send_to: `AW-633772404/${subscriptionId}`,
  });
}
