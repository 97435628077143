import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ErrorCode } from "../../../api/types";
import {
  toastRequested,
  workspaceDeletionRequested,
  workspaceUpdateRequested,
} from "../../../store/actions";
import { IRootState } from "../../../store/store";
import { getAccessToken } from "../../../utils/AuthUtils";
import Button from "../../components/layout/utils/Button";
import Card from "../../components/layout/utils/Card";
import Modal from "../../components/layout/utils/Modal";
import { PageLoader } from "../../components/layout/utils/PageLoader";

export default function Settings() {
  const dispatch = useDispatch();
  const [errorCode, setErrorCode] = useState<ErrorCode | null>(null);
  const [deleteWorkspaceModalOpen, setDeleteWorkspaceModalOpen] =
    useState<boolean>(false);

  const activeCustomerWorkspace = useSelector(
    (state: IRootState) => state.activeWorkspace
  );

  const [newWorkspaceName, setNewWorkspaceName] = useState("");

  useEffect(() => {
    if (activeCustomerWorkspace) {
      setNewWorkspaceName(activeCustomerWorkspace.workspace.name);
    }
  }, [activeCustomerWorkspace?.workspace.id]);

  function handleDeleteWorkapce(event: React.FormEvent) {
    event.preventDefault();

    let accessToken = getAccessToken();

    if (!accessToken || !activeCustomerWorkspace) return;

    dispatch(
      workspaceDeletionRequested(
        accessToken,
        activeCustomerWorkspace.workspace.id
      )
    );

    setDeleteWorkspaceModalOpen(false);
  }

  function handleUpdateWorkspaceName() {
    let accessToken = getAccessToken();
    if (!accessToken || !activeCustomerWorkspace) return;

    if (!newWorkspaceName) {
      setErrorCode(ErrorCode.EMPTY_WORKSPACE_NAME);
      dispatch(
        toastRequested({
          type: "error",
          message: "Workspace name cannot be empty",
        })
      );
      return;
    }

    dispatch(
      workspaceUpdateRequested(
        accessToken,
        activeCustomerWorkspace.workspace.id,
        newWorkspaceName
      )
    );
  }

  function isNameError(): boolean {
    return errorCode === ErrorCode.EMPTY_WORKSPACE_NAME;
  }

  if (!activeCustomerWorkspace) {
    return <PageLoader />;
  }

  return (
    <div className='max-w-3xl mx-auto'>
      <Card title='Change workspace name'>
        <div className='block'>
          <label
            className='block text-gray-800 text-sm font-medium mb-1'
            htmlFor='email'
          >
            Workspace Name <span className='text-red-600'>*</span>
          </label>
          <div className='block'>
            <input
              id='api-key-name'
              className={`form-input w-80 text-gray-800 ${
                isNameError() ? "!border-rose-500" : ""
              }`}
              placeholder='Name here'
              value={newWorkspaceName}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setNewWorkspaceName(event.target.value)
              }
            />
          </div>
          <div className='mt-3'>
            <Button
              type='button'
              color='primary'
              size='regular'
              onClick={handleUpdateWorkspaceName}
              text='Save'
            />
          </div>
        </div>
      </Card>

      <Card title='Delete workspace' className='mt-8'>
        <p>
          Delete permanently all the data associated to the workspace. This
          action is not reversible.
        </p>
        <div className='block mt-3'>
          <Button
            type='button'
            color='alert'
            size='regular'
            onClick={() => {
              setTimeout(() => setDeleteWorkspaceModalOpen(true));
            }}
            text='Delete'
          />
        </div>
      </Card>

      <Modal
        id='delete-workspace-modal'
        title='Delete workspace'
        modalOpen={deleteWorkspaceModalOpen}
        setModalClosed={() => setDeleteWorkspaceModalOpen(false)}
      >
        <form onSubmit={handleDeleteWorkapce}>
          <div className='w-full mt-5'>
            <p className='mb-3 mr-5'>
              Are you sure? This action is not reversible
            </p>
            <Button
              type='submit'
              color='alert'
              text='Delete'
              size='large'
              width='max'
            />
          </div>
        </form>
      </Modal>
    </div>
  );
}
