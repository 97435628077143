import { ReactNode, useEffect, useState } from "react";

import Transition from "./Transition";

type TooptipPosition = "right" | "left" | "bottom" | "top";
type TooptipSize = "lg" | "md" | "sm" | "xs";
type TooptipBg = "light" | "dark" | "else"; // TODO replace else

type TooltipProps = {
  text: string;
  children: ReactNode;
  bg?: TooptipBg;
  size?: TooptipSize;
  position?: TooptipPosition;
  delayMs?: number;
};

function Tooltip({
  text,
  children,
  bg,
  size,
  position,
  delayMs = 500,
}: TooltipProps) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [mouseOn, setMouseOn] = useState(false);

  const positionOuterClasses = (position?: TooptipPosition) => {
    switch (position) {
      case "right":
        return "left-full top-1/2 -translate-y-1/2";
      case "left":
        return "right-full top-1/2 -translate-y-1/2";
      case "bottom":
        return "top-full left-1/2 -translate-x-1/2";
      default:
        return "bottom-full left-1/2 -translate-x-1/2";
    }
  };

  const sizeClasses = (size?: TooptipSize) => {
    switch (size) {
      case "lg":
        return "min-w-72 p-3";
      case "md":
        return "min-w-56 p-3";
      case "sm":
        return "min-w-44 p-2";
      default:
        return "p-2";
    }
  };

  const colorClasses = (bg?: TooptipBg) => {
    switch (bg) {
      case "light":
        return "bg-white text-slate-600 border-slate-200";
      case "dark":
        return "bg-slate-700 text-slate-100 border-slate-600";
      default:
        return "text-slate-600 bg-white border-slate-200";
    }
  };

  const positionInnerClasses = (position?: TooptipPosition) => {
    switch (position) {
      case "right":
        return "ml-2";
      case "left":
        return "mr-2";
      case "bottom":
        return "mt-2";
      default:
        return "mb-2";
    }
  };

  useEffect(() => {
    if (!mouseOn) {
      setTooltipOpen(false); // TODO false
    } else {
      let timeoutId = setTimeout(() => {
        setTooltipOpen(true);
      }, delayMs);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [mouseOn, delayMs]);

  return (
    <div className='relative inline-block'>
      <span
        onMouseEnter={() => setMouseOn(true)}
        onMouseLeave={() => setMouseOn(false)}
        onFocus={() => setMouseOn(true)}
        onBlur={() => setMouseOn(false)}
      >
        {children}
      </span>
      <div className={`z-10 absolute ${positionOuterClasses(position)}`}>
        <Transition
          show={tooltipOpen}
          tag='div'
          className={`rounded border overflow-hidden shadow-lg ${sizeClasses(
            size
          )} ${colorClasses(bg)} ${positionInnerClasses(position)}`}
          enter='transition ease-out duration-200 transform'
          enterStart='opacity-0'
          enterEnd='opacity-100'
          leave='transition ease-out duration-200'
          leaveStart='opacity-100'
          leaveEnd='opacity-0'
          appear={true}
        >
          <div>{text}</div>
        </Transition>
      </div>
    </div>
  );
}

export default Tooltip;
