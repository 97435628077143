import { useDispatch } from "react-redux";

import { ApiKeyDto, ErrorResponse } from "../../../api/types";
import { deleteApiKey } from "../../../api/workspace";
import { toastRequested } from "../../../store/actions";
import { getAccessToken } from "../../../utils/AuthUtils";
import { getErrorMessage } from "../../../utils/ErrorUtils";
import { Format, formatEpochSeconds } from "../../../utils/TimeUtils";
import Button from "../layout/utils/Button";
import Card from "../layout/utils/Card";
import Icon from "../layout/utils/Icon";
import Tooltip from "../layout/utils/Tooltip";

export function ApiKeysTable({
  apiKeys,
  activeWorkspaceId,
  reloadApiKeys,
  handleNewApiKeyOpen,
}: {
  apiKeys: ApiKeyDto[];
  activeWorkspaceId: string;
  reloadApiKeys: () => void;
  handleNewApiKeyOpen: () => void;
}) {
  const dispatch = useDispatch();

  function handleDeleteApiKey(id: string): void {
    let accessToken = getAccessToken();
    if (!accessToken) return;

    deleteApiKey(accessToken, activeWorkspaceId, id)
      .then(() => {
        reloadApiKeys();
        dispatch(
          toastRequested({ type: "success", message: "Api key deleted" })
        );
      })
      .catch((errorResponse: ErrorResponse) => {
        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      });
  }

  return (
    <Card
      title='API Keys'
      cardActions={[
        {
          text: "New key",
          iconClass: "bi-plus-lg",
          handler: handleNewApiKeyOpen,
        },
      ]}
    >
      <div className='p-3'>
        {/* Table */}
        <div className='overflow-x-auto'>
          <table className='table-auto w-full'>
            {/* Table header */}
            <thead className='text-xs uppercase text-slate-400 bg-slate-50 rounded-sm'>
              <tr>
                <th className='p-2'>
                  <div className='font-semibold text-left'>
                    Name
                    <Icon className='bi-caret-down-fill ml-1' size='base' />
                  </div>
                </th>
                <th className='p-2'>
                  <div className='font-semibold text-left'>API key</div>
                </th>
                <th className='p-2'>
                  <div className='font-semibold text-left'>Created</div>
                </th>
                <th className='p-2'>
                  <div className='font-semibold text-right'></div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className='text-sm font-medium divide-y divide-slate-100'>
              {/* Row */}
              {apiKeys.map((apiKeyDto: ApiKeyDto) => (
                <tr key={apiKeyDto.id}>
                  <td className='p-2'>{apiKeyDto.name}</td>
                  <td className='p-2'>
                    <div className='text-left'>{apiKeyDto.apiKey}</div>
                  </td>
                  <td className='p-2'>
                    {formatEpochSeconds(apiKeyDto.createdAt, Format.SHORT)}
                  </td>

                  <td className='p-2'>
                    <div className='text-right min-w-[5rem]'>
                      <Tooltip text='Delete'>
                        <Button
                          iconClass='bi-trash'
                          size='small'
                          iconSize='lg'
                          color='alert'
                          onClick={() => handleDeleteApiKey(apiKeyDto.id)}
                        />
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
}
