import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { IRootState } from "../../../store/store";
import { isAdmin } from "../../../utils/AuthUtils";
import { getDocumentationUrl } from "../../../utils/UrlUtils";
import DropdownWorkspaces from "./utils/DropdownWorkspaces";

type MenuElement = {
  title: string;
  url: string;
  logo: string;
  activeLogo: string;
};

export default function Sidebar({
  sidebarOpen,
  setSidebarOpen,
  sidebarExpanded,
  setSidebarExpanded,
}: {
  sidebarOpen: boolean;
  setSidebarOpen: (newValue: boolean) => void;
  sidebarExpanded: boolean;
  setSidebarExpanded: (newValue: boolean) => void;
}) {
  const trigger = useRef<HTMLButtonElement>(null);
  const sidebar = useRef<HTMLDivElement>(null);

  const activeCustomerWorkspace = useSelector(
    (state: IRootState) => state.activeWorkspace
  );

  let location = useLocation();

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!sidebar.current) return;
      if (
        !sidebarOpen ||
        (target instanceof HTMLElement && sidebar.current.contains(target)) ||
        (target instanceof HTMLElement && trigger.current?.contains(target))
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: { keyCode: number }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  function handleOpenDocs(): void {
    window.open(getDocumentationUrl(), "_blank");
  }

  function getMenuElements(): MenuElement[] {
    let menuElements = [
      {
        title: "Dashboard",
        url: "/dashboard",
        logo: "bi-house",
        activeLogo: "bi-house-fill",
      },
      {
        title: "Templates",
        url: "/templates",
        logo: "bi-file-code",
        activeLogo: "bi-file-code-fill",
      },
      {
        title: "API keys",
        url: "/api-keys",
        logo: "bi-key",
        activeLogo: "bi-key-fill",
      },
      {
        title: "Members",
        url: "/members",
        logo: "bi-people",
        activeLogo: "bi-people-fill",
      },
      {
        title: "Subscription",
        url: "/subscription",
        logo: "bi-credit-card",
        activeLogo: "bi-credit-card-fill",
      },
    ];

    if (isAdmin(activeCustomerWorkspace)) {
      menuElements.push({
        title: "Settings",
        url: "/settings",
        logo: "bi-gear",
        activeLogo: "bi-gear-fill",
      });
    }

    return menuElements;
  }

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden='true'
      ></div>

      {/* Sidebar */}
      <div
        id='sidebar'
        ref={sidebar}
        className={`flex flex-col absolute z-40 top-0 lg:relative lg:left-0 lg:top-auto h-screen overflow-y-scroll ${
          sidebarExpanded ? "lg:w-64" : ""
        } lg:overflow-y-auto no-scrollbar w-64 lg:w-20 2xl:w-64 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "left-0" : "-left-64"
        } !overflow-visible`}
      >
        {/* Sidebar header */}
        <div className='lg:flex justify-between px-3 py-2'>
          {/* Close button */}
          <button
            ref={trigger}
            className='lg:hidden -mt-1 -ml-3 mb-5 text-slate-500 hover:text-slate-400'
            onClick={() => setSidebarOpen(false)}
            aria-controls='sidebar'
            aria-expanded={sidebarOpen}
          >
            <span className='sr-only'>Close sidebar</span>
            <svg
              className='w-6 h-6 fill-current'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z' />
            </svg>
          </button>
          <DropdownWorkspaces
            sidebarExpanded={sidebarExpanded}
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
        </div>

        <hr className='border-slate-600 -mx-4' />

        {/* Links */}
        <div className='space-y-8'>
          {/* Pages group */}
          <div>
            <ul className='my-2'>
              {getMenuElements().map((menuElement: MenuElement) => (
                <li
                  key={menuElement.title}
                  className={`px-3 py-1 rounded-sm mb-0.5 last:mb-0 ${
                    location.pathname.includes(menuElement.url)
                      ? "bg-slate-900"
                      : ""
                  }`}
                >
                  <Link
                    to={menuElement.url}
                    className={`block text-slate-200 truncate transition duration-150 ${
                      location.pathname.includes(menuElement.url)
                        ? "hover:text-slate-200"
                        : "hover:text-white"
                    }`}
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                  >
                    <div className='flex items-center'>
                      <i
                        className={`text-2xl bi ${
                          location.pathname.includes(menuElement.url)
                            ? menuElement.activeLogo + " text-indigo-500 "
                            : menuElement.logo
                        }`}
                      ></i>
                      <span
                        className={`text-sm font-medium ml-3 lg:opacity-0 ${
                          sidebarExpanded ? "lg:opacity-100" : ""
                        } 2xl:opacity-100 duration-200`}
                      >
                        {menuElement.title}
                      </span>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <hr className='border-slate-600 -mx-4' />

        <div>
          <div className='px-3 py-1 mt-2'>
            <Link
              to=''
              className={`block text-slate-200 truncate transition duration-150 hover:text-white`}
              onClick={handleOpenDocs}
            >
              <div className='flex items-center'>
                <i className={`text-2xl bi bi-book`}></i>
                <span
                  className={`text-sm font-medium ml-3 lg:opacity-0 ${
                    sidebarExpanded ? "lg:opacity-100" : ""
                  } 2xl:opacity-100 duration-200`}
                >
                  API Reference
                </span>
              </div>
            </Link>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className='pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto'>
          <div className='px-3 py-2'>
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className='sr-only'>Expand / collapse sidebar</span>
              <svg
                className={`w-6 h-6 fill-current ${
                  sidebarExpanded ? "rotate-180" : ""
                }`}
                viewBox='0 0 24 24'
              >
                <path
                  className='text-slate-400'
                  d='M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z'
                />
                <path className='text-slate-600' d='M3 23H1V1h2z' />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
