import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { approveInvite, getInviteDetails } from "../../../api/invites";
import { ErrorResponse, WorkspaceInviteDto } from "../../../api/types";
import { toastRequested } from "../../../store/actions";
import { getAccessToken } from "../../../utils/AuthUtils";
import { getErrorMessage } from "../../../utils/ErrorUtils";
import Button from "../../components/layout/utils/Button";

const JoinWorkspace = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const requestDone = useRef(false);
  const accessToken = getAccessToken();
  const [searchParams] = useSearchParams();
  const [invite, setInvite] = useState<WorkspaceInviteDto | null>(null);

  let inviteId = searchParams.get("invite");

  useEffect(() => {
    if (!inviteId) {
      navigate("/");
    } else {
      if (requestDone.current) {
        return;
      }

      if (accessToken) {
        requestDone.current = true;
        approveInvite(accessToken, inviteId)
          .then(() => {
            navigate("/dashboard");
          })
          .catch((errorResponse: ErrorResponse) => {
            dispatch(
              toastRequested({
                type: "error",
                message: getErrorMessage(errorResponse),
              })
            );
          });
      } else {
        getInviteDetails(inviteId)
          .then((inviteDto: WorkspaceInviteDto) => {
            setInvite(inviteDto);
          })
          .catch((errorResponse: ErrorResponse) => {
            dispatch(
              toastRequested({
                type: "error",
                message: getErrorMessage(errorResponse),
              })
            );
          });
      }
    }
  }, [dispatch, navigate, searchParams, accessToken, inviteId]);

  function handleLogin() {
    if (!inviteId) return;

    navigate({
      pathname: "/login",
      search: `?${createSearchParams({
        redirect: "/join-workspace?invite=" + inviteId,
      })}`,
    });
  }

  function handleRegister() {
    if (!inviteId) return;

    navigate({
      pathname: "/register",
      search: `?${createSearchParams({ invite_id: inviteId })}`,
    });
  }

  if (!invite) return <></>; // TODO Something went wrong

  return (
    <section className='font-inter antialiased tracking-tight bg-gradient-to-b from-gray-100 to-white'>
      <div className='max-w-6xl mx-auto px-4 sm:px-6'>
        <div className='pt-32 pb-12 md:pt-60 md:pb-20'>
          {/* Page header */}
          <div className='max-w-3xl mx-auto text-center pb-12 md:pb-20'>
            <h1 className='h3'>
              You have been invited to join{" "}
              <span className='font-bold text-blue-600'>
                {invite?.workspace.name}{" "}
              </span>
              workspace.
            </h1>
            <p className='text-xl text-gray-600 mt-2 max-w-xl inline-block margin-auto'>
              Transform HTML into custom PDFs with DynamoPDF's API. Create,
              convert, and share documents easily.
            </p>
            <div>
              <Button
                size='large'
                color='secondary'
                className='mt-7'
                text='Login'
                onClick={handleLogin}
              />
              <Button
                size='large'
                color='secondary'
                className='mt-7 ml-4'
                text='Register'
                onClick={handleRegister}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JoinWorkspace;
