import { useEffect, useRef, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { verifyEmail } from "../../../api/auth";
import { ErrorCode, ErrorResponse } from "../../../api/types";
import Button from "../../components/layout/utils/Button";

function VerifyEmail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const requestDone = useRef(false);
  const [emailVerificationSuccessful, setEmailVerificationSuccessful] =
    useState<boolean | null>(null);
  const [emailVerificationError, setEmailVerificationError] =
    useState<ErrorResponse | null>(null);

  let email = searchParams.get("email");
  let token = searchParams.get("token");

  useEffect(() => {
    if (!email || !token) {
      navigate("/");
    } else {
      if (requestDone.current) {
        return;
      }
      requestDone.current = true;

      verifyEmail(email, token)
        .then(() => {
          setEmailVerificationSuccessful(true);
        })
        .catch((errorResponse: ErrorResponse) => {
          setEmailVerificationSuccessful(false);
          setEmailVerificationError(errorResponse);
        });
    }
  }, [navigate, searchParams, email, token]);

  function getHeader() {
    if (isEmailVerificataionSuccessful()) {
      return "Email successfully verified";
    } else if (isEmailAlreadyVerified()) {
      return "Email already verified.";
    } else {
      return "Something went wrong.";
    }
  }

  function getDescription() {
    if (isEmailVerificataionSuccessful()) {
      return "You can now sign in.";
    } else if (isEmailAlreadyVerified()) {
      return "You can sign in.";
    } else {
      return "";
    }
  }

  function isSignInButtonVisible() {
    return isEmailVerificataionSuccessful() || isEmailAlreadyVerified();
  }

  function isEmailVerificataionSuccessful() {
    return emailVerificationSuccessful === true;
  }

  function isEmailAlreadyVerified() {
    return (
      emailVerificationError?.errorCode === ErrorCode.EMAIL_ALREADY_VERIFIED
    );
  }

  function handleSignIn() {
    if (!email) return;

    navigate({
      pathname: "/login",
      search: `?${createSearchParams({
        email: email,
      })}`,
    });
  }

  if (emailVerificationSuccessful === null) {
    return <section></section>;
  }

  return (
    <section className='font-inter antialiased tracking-tight bg-gradient-to-b from-gray-100 to-white'>
      <div className='max-w-6xl mx-auto px-4 sm:px-6'>
        <div className='pt-32 pb-12 md:pt-60 md:pb-20'>
          {/* Page header */}
          <div className='max-w-3xl mx-auto text-center pb-12 md:pb-20'>
            <h1 className='h3'>{getHeader()}</h1>
            <p className='text-xl text-gray-600 mb-8'>{getDescription()}</p>
            {isSignInButtonVisible() ? (
              <div>
                <Button
                  color='secondary'
                  size='large'
                  text='Sign in'
                  onClick={handleSignIn}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default VerifyEmail;
