import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ActivityDto,
  ActivityLogResponse,
  AnalyticsResponse,
  ErrorResponse,
  MonthlyUsageDto,
  OverallAnalyticsResponse,
  SubscriptionDto,
} from "../../../api/types";
import {
  getActivities,
  getAnalytics,
  getSubscription,
} from "../../../api/workspace";
import { toastRequested } from "../../../store/actions";
import { IRootState } from "../../../store/store";
import { getAccessToken } from "../../../utils/AuthUtils";
import { getErrorMessage } from "../../../utils/ErrorUtils";
import { ActivitiesTable } from "../../components/app/ActivitiesTable";
import AnalyticsCard from "../../components/app/AnalyticsCard";
import UsageCard from "../../components/app/UsageCard";
import { PageLoader } from "../../components/layout/utils/PageLoader";

export default function Dashboard() {
  const dispatch = useDispatch();
  const [activities, setActivities] = useState<ActivityDto[] | null>(null);
  const [activitiesPage, setActivitiesPage] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [overallAnalyticsResponse, setOverallAnalyticsResponse] =
    useState<OverallAnalyticsResponse | null>(null);
  const [monthlyUsages, setMonthlyUsages] = useState<MonthlyUsageDto[] | null>(
    null
  );
  const [subscription, setSubscription] = useState<SubscriptionDto | null>(
    null
  );
  const accessToken = getAccessToken();

  let activeCustomerWorkspace = useSelector(
    (state: IRootState) => state.activeWorkspace
  );

  const loadActivities = useCallback(
    (accessToken: string, workspaceId: string, page: number) => {
      getActivities(accessToken, workspaceId, page)
        .then((apiKeysResponse: ActivityLogResponse) => {
          setActivities(apiKeysResponse.activities);
          setHasNextPage(apiKeysResponse.hasNextPage);
        })
        .catch((errorResponse: ErrorResponse) => {
          dispatch(
            toastRequested({
              type: "error",
              message: getErrorMessage(errorResponse),
            })
          );
        });
    },
    [dispatch]
  );

  const loadAnalytics = useCallback(
    (accessToken: string, workspaceId: string) => {
      getAnalytics(accessToken, workspaceId)
        .then((analyticsResponse: AnalyticsResponse) => {
          setMonthlyUsages(analyticsResponse.monthlyUsages);
          setOverallAnalyticsResponse(analyticsResponse.overallAnalytics);
        })
        .catch((errorResponse: ErrorResponse) => {
          dispatch(
            toastRequested({
              type: "error",
              message: getErrorMessage(errorResponse),
            })
          );
        });
    },
    [dispatch]
  );

  useEffect(() => {
    if (accessToken && activeCustomerWorkspace) {
      loadActivities(
        accessToken,
        activeCustomerWorkspace.workspace.id,
        activitiesPage
      );
    }
  }, [loadActivities, activitiesPage, activeCustomerWorkspace?.workspace.id]);

  useEffect(() => {
    if (accessToken && activeCustomerWorkspace) {
      loadAnalytics(accessToken, activeCustomerWorkspace.workspace.id);
    }

    return () => {
      setMonthlyUsages(null);
      setOverallAnalyticsResponse(null);
    };
  }, [loadAnalytics, activeCustomerWorkspace?.workspace.id]);

  useEffect(() => {
    if (accessToken && activeCustomerWorkspace) {
      getSubscription(accessToken, activeCustomerWorkspace.workspace.id)
        .then((subscription: SubscriptionDto) => {
          setSubscription(subscription);
        })
        .catch((errorResponse: ErrorResponse) => {
          dispatch(
            toastRequested({
              type: "error",
              message: getErrorMessage(errorResponse),
            })
          );
        });
    }

    return () => {
      setSubscription(null);
    };
  }, [dispatch, activeCustomerWorkspace?.workspace.id]);

  function handlePreviousPageClicked() {
    setActivitiesPage((previousPage) => {
      if (previousPage === 0) return previousPage;
      return previousPage - 1;
    });
  }

  function handleNextPageClicked() {
    setActivitiesPage((previousPage) => previousPage + 1);
  }

  if (
    !activeCustomerWorkspace ||
    !activities ||
    !overallAnalyticsResponse ||
    !monthlyUsages ||
    !subscription
  ) {
    return <PageLoader />;
  }

  const Tile = ({ title, val }: { title: string; val: string | number }) => (
    <div className='flex flex-wrap flex-row sm:flex-col lg:flex-row col-span-full sm:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200 p-5'>
      <div className='grow flex items-center'>
        <div className='text-md font-semibold mr-1'>{title}</div>
      </div>
      <div className='grow-0 flex items-center'>
        <div className='align-middle'>{val}</div>
      </div>
    </div>
  );

  return (
    <>
      <div className='max-w-6xl m-auto grid grid-cols-12 gap-6'>
        {Tile({
          title: "Current month usage",
          val: `${overallAnalyticsResponse.currentMonthUsage} / ${subscription.product.monthlyUsageLimit}`,
        })}
        {Tile({
          title: "Last 6 months usage",
          val: overallAnalyticsResponse.last6MonthsUsage,
        })}
        {Tile({
          title: "All time usage",
          val: overallAnalyticsResponse.totalUsage,
        })}
      </div>
      <div className='max-w-6xl m-auto grid grid-cols-12 gap-6 mt-8'>
        <div className='col-span-full sm:col-span-4'>
          <UsageCard
            monthlyUsageLimit={subscription.product.monthlyUsageLimit}
            currentMonthUsage={overallAnalyticsResponse.currentMonthUsage}
          />
        </div>
        <div className='col-span-full sm:col-span-8'>
          <AnalyticsCard monthlyUsages={monthlyUsages} />
        </div>
      </div>
      <div className='mt-8 mb-40'>
        <ActivitiesTable
          activities={activities}
          previousPageDisabled={activitiesPage === 0}
          nextPageDisabled={!hasNextPage}
          onPreviousPageClick={handlePreviousPageClicked}
          onNextPageClick={handleNextPageClicked}
        />
      </div>
    </>
  );
}
