import { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { ErrorScreen } from "./ErrorScreen";

interface ApplicationErrorBoundaryProperties {
  errorCallback: (error: Error, info?: { componentStack: string }) => void;
  children: ReactNode;
}

export const ApplicationErrorBoundary = ({
  errorCallback,
  children,
}: ApplicationErrorBoundaryProperties) => {
  return (
    <ErrorBoundary onError={errorCallback} FallbackComponent={ErrorScreen}>
      {children}
    </ErrorBoundary>
  );
};
