import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CustomerWorkspaceRole,
  ErrorCode,
  ErrorResponse,
  InviteDto,
  WorkspaceCustomerDto,
  WorkspaceCustomersResponse,
  WorkspaceInvitesResponse,
} from "../../../api/types";
import {
  createInvite,
  getInvites,
  getMembers,
  updateWorkspaceMember,
} from "../../../api/workspace";
import { toastRequested } from "../../../store/actions";
import { IRootState } from "../../../store/store";
import { getAccessToken } from "../../../utils/AuthUtils";
import { getErrorMessage } from "../../../utils/ErrorUtils";
import { InvitesTable } from "../../components/app/InvitesTable";
import { MembersTable } from "../../components/app/MembersTable";
import Button from "../../components/layout/utils/Button";
import Modal from "../../components/layout/utils/Modal";
import { PageLoader } from "../../components/layout/utils/PageLoader";

export default function Members() {
  const dispatch = useDispatch();
  const accessToken = getAccessToken();
  const [invites, setInvites] = useState<InviteDto[] | null>(null);
  const [members, setMembers] = useState<WorkspaceCustomerDto[] | null>(null);
  const [newInviteEmail, setNewInviteEmail] = useState<string | null>();
  const [createInviteModalOpen, setCreateInviteModalOpen] =
    useState<boolean>(false);
  const [editPermissionsModalOpen, setEditPermissionsModalOpen] =
    useState<boolean>(false);
  const [newCustomerWorkspaceRole, setNewCustomerWorkspaceRole] =
    useState<CustomerWorkspaceRole | null>(null);
  const [activeCustomerId, setActiveCustomerId] = useState<string | null>();

  const [formErrorCode, setFormErrorCode] = useState<ErrorCode | null>(null);

  const activeCustomerWorkspace = useSelector(
    (state: IRootState) => state.activeWorkspace
  );

  const loadInvites = useCallback(
    (accessToken: string, workspaceId: string) => {
      getInvites(accessToken, workspaceId)
        .then((WorkspaceInvitesResponse: WorkspaceInvitesResponse) => {
          setInvites(WorkspaceInvitesResponse.invites.map((i) => i.invite));
        })
        .catch((errorResponse: ErrorResponse) => {
          dispatch(
            toastRequested({
              type: "error",
              message: getErrorMessage(errorResponse),
            })
          );
        });
    },
    [dispatch]
  );

  const loadMembers = useCallback(
    (accessToken: string, workspaceId: string) => {
      getMembers(accessToken, workspaceId)
        .then((workspaceCustomersResponse: WorkspaceCustomersResponse) => {
          setMembers(workspaceCustomersResponse.customers);
        })
        .catch((errorResponse: ErrorResponse) => {
          dispatch(
            toastRequested({
              type: "error",
              message: getErrorMessage(errorResponse),
            })
          );
        });
    },
    [dispatch]
  );

  useEffect(() => {
    if (accessToken && activeCustomerWorkspace) {
      loadInvites(accessToken, activeCustomerWorkspace.workspace.id);
      loadMembers(accessToken, activeCustomerWorkspace.workspace.id);
    }

    return () => {
      setInvites(null);
      setMembers(null);
    };
  }, [
    accessToken,
    activeCustomerWorkspace?.workspace.id,
    loadInvites,
    loadMembers,
  ]);

  useEffect(() => {
    if (!createInviteModalOpen) {
      setNewInviteEmail(null);
      setNewCustomerWorkspaceRole(null);
      setActiveCustomerId(null);
      setFormErrorCode(null);
    }
  }, [createInviteModalOpen]);

  function handleCreateInvite(event: FormEvent): void {
    event.preventDefault();

    let accessToken = getAccessToken();
    if (!accessToken || !activeCustomerWorkspace || !newInviteEmail) return;

    createInvite(
      accessToken,
      activeCustomerWorkspace.workspace.id,
      newInviteEmail
    )
      .then(() => {
        if (!accessToken) return;

        setCreateInviteModalOpen(false);
        loadInvites(accessToken, activeCustomerWorkspace.workspace.id);
        dispatch(
          toastRequested({
            type: "success",
            message: "Invite sent",
          })
        );
      })
      .catch((errorResponse: ErrorResponse) => {
        setFormErrorCode(errorResponse.errorCode);

        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      });
  }

  function handleUpdateWorkspaceCustomer(event: FormEvent): void {
    event.preventDefault();

    let accessToken = getAccessToken();
    if (
      !accessToken ||
      !activeCustomerWorkspace ||
      !activeCustomerId ||
      !newCustomerWorkspaceRole
    )
      return;

    updateWorkspaceMember(
      accessToken,
      activeCustomerWorkspace.workspace.id,
      activeCustomerId,
      [newCustomerWorkspaceRole]
    )
      .then(() => {
        if (!accessToken) return;

        setEditPermissionsModalOpen(false);
        loadMembers(accessToken, activeCustomerWorkspace.workspace.id);
      })
      .catch((errorResponse: ErrorResponse) => {
        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      });
  }

  function openNewInviteModal() {
    setTimeout(() => setCreateInviteModalOpen(true));
  }

  function openUpdatePermissionsModal(workspaceCustomer: WorkspaceCustomerDto) {
    setActiveCustomerId(workspaceCustomer.customer.id);
    setNewCustomerWorkspaceRole(workspaceCustomer.roles[0]);

    setTimeout(() => setEditPermissionsModalOpen(true));
  }

  function isEmailError() {
    return formErrorCode === ErrorCode.CUSTOMER_ALREADY_INVITED;
  }

  if (!activeCustomerWorkspace || !invites || !members || !accessToken) {
    return <PageLoader />;
  }

  return (
    <>
      <div>
        <MembersTable
          members={members}
          activeWorkspaceId={activeCustomerWorkspace.workspace.id}
          reloadMembers={() =>
            loadMembers(accessToken, activeCustomerWorkspace.workspace.id)
          }
          handleNewMemberOpen={openNewInviteModal}
          handleEditPermissionsOpen={openUpdatePermissionsModal}
        />
      </div>
      {invites.length > 0 ? (
        <div className='mt-8'>
          <InvitesTable
            invites={invites}
            activeWorkspaceId={activeCustomerWorkspace.workspace.id}
            reloadInvites={() =>
              loadInvites(accessToken, activeCustomerWorkspace.workspace.id)
            }
          />
        </div>
      ) : (
        ""
      )}

      <Modal
        id='create-invite-modal'
        title='Invite new member.'
        modalOpen={createInviteModalOpen}
        setModalClosed={() => setCreateInviteModalOpen(false)}
      >
        <form onSubmit={handleCreateInvite}>
          <label
            className='block text-gray-800 text-sm font-medium mb-1 mt-5'
            htmlFor='email'
          >
            Email <span className='text-red-600'>*</span>
          </label>
          <input
            id='invite-name'
            className={`form-input w-full text-gray-800 ${
              isEmailError() ? "!border-rose-500" : ""
            }`}
            placeholder='Email here'
            required
            value={newInviteEmail || ""}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setNewInviteEmail(event.target.value)
            }
          />
          <div className='w-full mt-5'>
            <Button
              type='submit'
              color='primary'
              text='Invite'
              size='large'
              width='max'
            />
          </div>
        </form>
      </Modal>

      <Modal
        id='change-permissions-modal'
        title='Update permissions.'
        modalOpen={editPermissionsModalOpen}
        setModalClosed={() => setEditPermissionsModalOpen(false)}
      >
        <form onSubmit={handleUpdateWorkspaceCustomer}>
          <label
            className='block text-gray-800 text-sm font-medium mb-1 mt-5'
            htmlFor='email'
          >
            Role <span className='text-red-600'>*</span>
          </label>
          <select
            className='w-full form-input text-gray-800'
            value={newCustomerWorkspaceRole || CustomerWorkspaceRole.USER}
            onChange={(event) =>
              setNewCustomerWorkspaceRole(
                event.target.value as CustomerWorkspaceRole
              )
            }
          >
            <option value={CustomerWorkspaceRole.ADMIN}>Admin</option>
            <option value={CustomerWorkspaceRole.USER}>User</option>
          </select>
          <div className='w-full mt-5'>
            <Button
              type='submit'
              color='primary'
              text='Save'
              size='large'
              width='max'
            />
          </div>
        </form>
      </Modal>
    </>
  );
}
