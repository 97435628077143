import { FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { startPasswordRecoveryChallenge } from "../../../api/auth";
import { ErrorResponse } from "../../../api/types";
import { toastRequested } from "../../../store/actions";
import { getErrorMessage } from "../../../utils/ErrorUtils";
import Button from "../../components/layout/utils/Button";

function PasswordRecovery() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");

  function handleResetPasswordStart(e: FormEvent) {
    e.preventDefault();

    if (!email) return;

    startPasswordRecoveryChallenge(email)
      .then(() => {
        dispatch(
          toastRequested({
            type: "success",
            message: "Email sent",
          })
        );
      })
      .catch((errorResponse: ErrorResponse) => {
        dispatch(
          toastRequested({
            type: "error",
            message: getErrorMessage(errorResponse),
          })
        );
      });
  }

  return (
    <section className='font-inter antialiased tracking-tight bg-gradient-to-b from-gray-100 to-white'>
      <div className='max-w-6xl mx-auto px-4 sm:px-6'>
        <div className='pt-32 pb-12 md:pt-60 md:pb-20'>
          {/* Page header */}
          <div className='max-w-3xl mx-auto text-center pb-12 md:pb-20'>
            <h1 className='h3'>Reset your password</h1>
            <p className='text-xl text-gray-600 mb-8'>
              Enter your email address below and we'll send you a link with
              instructions.
            </p>
            <div className='w-full px-3'>
              <form onSubmit={handleResetPasswordStart}>
                <div className='max-w-xs m-auto'>
                  <input
                    id='email'
                    type='email'
                    className='form-input w-full text-gray-800'
                    placeholder='Enter your email'
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className='mt-5'>
                  <Button
                    type='submit'
                    color='secondary'
                    size='large'
                    text='Send email'
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PasswordRecovery;
